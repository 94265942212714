<template>
    <div class="table-sticky-wrapper">
        <slot name="default" :ref="`reference`"></slot>

        <div class="table-sticky-wrapper__scroller"
             :style="{'margin-top':`-${scrollBarWidth}px`}"
             ref="scroller"
             @scroll.passive="onScrollControl">
            <div class="data-table__scroller-inner" :style="{width:`${scrollWidth}px`}"></div>
        </div>
    </div>
</template>
<script>
import throttle from "storemoodify/util/throttle"
import supportPassive from "storemoodify/util/support-passive-event"
import ResizeObserver from "resize-observer-polyfill"
import scrollBarWidth from "../util/scrollbar-width"

const getPassiveOptions = () => {
    if (supportPassive()) {
        return { passive: true }
    }
}

export default {
    data() {
        return {
            scrollWidth: 0,
            scrollBarWidth: scrollBarWidth(),
        }
    },

    mounted() {
        this.onScrollWindow = this.onScrollWindow.bind(this)
        this.$root = this.$slots.default[0].elm
        this.$table = this.$root.querySelector(".v-data-table__wrapper")
        this.$thead = this.$table.querySelector("thead tr")
        this.$theads = Array.from(this.$thead.querySelectorAll("th"))
        this.$theadTop = 0
        this.observe()
    },

    beforeDestroy() {
        this.unobserve()
    },

    methods: {
        observe() {
            this.$onScrollTable = throttle((e) => this.onScrollTable(e), 50)
            this.$resizeObserver = new ResizeObserver((...args) => this.onResizeTable(...args))
            this.$resizeObserver.observe(this.$table)
            this.$table.addEventListener("scroll", this.$onScrollTable, getPassiveOptions())
            window.addEventListener("scroll", this.onScrollWindow, false)
        },

        unobserve() {
            this.$table.removeEventListener("scroll", this.$onScrollTable)
            window.removeEventListener("scroll", this.onScrollWindow)
            this.$resizeObserver.disconnect()
        },

        onScrollWindow() {
            const appTop = this.$vuetify.application.top
            const rectTop = this.$table.getBoundingClientRect().top |> Math.floor
            const top = appTop - rectTop < 0 ? 0 : rectTop - appTop|> Math.abs |> Math.floor
            this.$thead.style.transform = `translate3d(0, ${top}px, 0)`
        },

        onResizeTable() {
            const { scrollWidth } = this.$table
            this.scrollWidth = scrollWidth
            this.onScrollWindow()
        },

        onScrollTable(event) {
            const { scrollLeft } = event.target
            this.$refs.scroller.scrollTo(scrollLeft, 0)
        },

        onScrollControl(event) {
            const { scrollLeft } = event.target
            this.$table.scrollTo(scrollLeft, 0)
        },
    },
}
</script>
<style lang="stylus">
    .table-sticky-wrapper__scroller {
        position sticky
        bottom 0
        height 12px
        overflow-x: auto;

        .data-table__scroller-inner {
            height 1px
        }
    }

    .table-sticky-wrapper thead th {
        top: -1px
    }
</style>
