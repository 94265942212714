<template>
    <data-table
        class="photographers-table"
        v-model="getValue"
        :headers="headers"
        :loading="loading"
        :options.sync="opts"
        ref="table"
    >
        <template slot="body.prepend" v-if="!loading">
            <tr v-if="getValue.collection.length > 1">
                <td class="photographers-table__name">
                    <span class="title">{{ $t(`tables['total']`) }}</span>
                </td>
                <td class="subtitle-1 font-weight-bold">{{sumField("PhotosStatistic.sales")}}</td>

                <td class="subtitle-1 font-weight-bold">{{sumField("PhotosStatistic.salesAmountCash") | currency}}</td>
                <td class="subtitle-1 font-weight-bold">{{sumField("PhotosStatistic.salesAmountPromo") | currency}}</td>
                <td class="subtitle-1 font-weight-bold">{{sumField("PhotosStatistic.salesRatio") | fixedNumber}}</td>

                <td class="subtitle-1 font-weight-bold">{{sumField("Profit") | currency}}</td>

                <td class="subtitle-1 font-weight-bold">{{sumField("PhotosStatistic.uploaded")}}</td>
                <td class="subtitle-1 font-weight-bold">{{sumField("viewed")}}</td>

                <td class="subtitle-1 font-weight-bold">{{sumField("PhotoGroupsStatistic.createdAll")}}</td>
                <td class="subtitle-1 font-weight-bold">{{sumField("PhotoGroupsStatistic.createdManual")}}</td>
                <td class="subtitle-1 font-weight-bold">{{sumField("PhotoGroupsStatistic.createdAutomatic")}}</td>
            </tr>
        </template>

        <template slot="item" slot-scope="props">
            <tr>
                <td class="photographers-table__name data-table__sticky_x">
                    <router-link :to="url(props.item.photographer)">
                        {{props.item.photographer.formattedName}}
                    </router-link>
                </td>

                <td>{{props.item.statistic.PhotosStatistic.sales}}</td>
                <td>{{props.item.statistic.PhotosStatistic.salesAmountCash | currency}}</td>
                <td>{{props.item.statistic.PhotosStatistic.salesAmountPromo | currency}}</td>
                <td>{{props.item.statistic.PhotosStatistic.salesRatio | fixedNumber}}</td>

                <td>{{props.item.statistic.Profit | currency}}</td>

                <td>{{props.item.statistic.PhotosStatistic.uploaded}}</td>

                <td>{{props.item.statistic.viewed}}</td>

                <td>{{props.item.statistic.PhotoGroupsStatistic.createdAll}}</td>
                <td>{{props.item.statistic.PhotoGroupsStatistic.createdManual}}</td>
                <td>{{props.item.statistic.PhotoGroupsStatistic.createdAutomatic}}</td>
            </tr>
        </template>
    </data-table>
</template>
<script>
import { $get } from "storemoodify/util/get"
import DataTable from "../DataTable"
import * as Format from "../../util/format"
import { i18n } from "../../plugins/i18n"

const TABLE_HEADERS = () => ([
    { text: i18n.t(`tables['photographer']`), align: "left", sortable: false, value: "name" },
    { text: i18n.t(`tables['bought']`), value: "PhotosStatistic.sales" },
    { text: i18n.t(`tables['cash']`), value: "PhotosStatistic.salesAmountCash" },
    { text: i18n.t(`tables['promo']`), value: "PhotosStatistic.salesAmountPromo" },
    { text: i18n.t(`tables['sales ratio']`), value: "PhotosStatistic.salesRatio" },

    { text: i18n.t(`tables['period profit']`), value: "PhotosStatistic.profit" },

    { text: i18n.t(`tables['uploaded']`), value: "PhotosStatistic.uploaded" },

    { text: i18n.t(`tables['profile viewed']`), value: "sViewed.viewed" },

    { text: i18n.t(`tables['photoset total']`), value: "PhotoGroupsStatistic.createdAll" },
    { text: i18n.t(`tables['photoset app']`), value: "PhotoGroupsStatistic.createdManual" },
    { text: i18n.t(`tables['photoset auto']`), value: "PhotoGroupsStatistic.createdAutomatic" },
])

export default {
    components: { DataTable },
    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
        loading: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Object,
            default: () => ({}),
        },
        url: {
            type: Function,
            default: () => () => "",
        }
    },

    data() {
        return {
            opts: Object.assign({}, this.options),
        }
    },

    computed: {
        getValue() {
            const { collection, count } = this.value
            collection.forEach(item => {
                item.photographer.formattedName = `${Format.getUserName(item.photographer.Name)}`
            })
            return { collection, count }
        },
        headers(){
            return TABLE_HEADERS()
        }
    },
    watch: {
        opts() {
            this.$emit("update:options", this.opts)
        },
        options() {
            this.opts = this.options
        },
    },
    methods: {
        sumField(fieldName) {
            const { collection } = this.value
            const field = `statistic.${fieldName}`

            return collection.reduce((res, item) => {
                const value = $get(item, field)
                return res += isNaN(value) ? 0 : Format.currencyAsNumber(value)
            }, 0)
        },
    },
}
</script>
<style>
    .photographers-table .photographers-table__name {
        min-width: 200px;
        white-space: nowrap;
    }

    .photographers-table th {
        white-space: nowrap;
    }
</style>
