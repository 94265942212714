<template>
    <div>
        <h3 class="title">{{ $t(`headers['income sources']`) }}</h3>

        <table-error v-if="canVisibleError" @again="onAgainFetch()"/>

        <div class="d-flex align-center justify-center flex-column py-10 my-10" v-if="loading">
            <v-progress-circular
                :size="50"
                color="blue-grey darken-4"
                indeterminate/>
            <p class="subtitle-1 mt-4">{{ $t(`messages['loading data']`) }}</p>
        </div>

        <div v-show="canVisibleData" ref="chart">
            <dynamic-chart v-if="canVisibleData" :options="getOptions" theme="area" v-model="getSeries"/>
        </div>

        <div v-show="visibleDetails" class="chart-details" ref="details">
            <div class="d-flex align-center justify-center flex-column py-10 my-10" v-if="canVisibleDetailsLoading">
                <v-progress-circular
                    :size="50"
                    color="blue-grey darken-4"
                    indeterminate/>
                <p class="subtitle-1 mt-4">{{ $t(`messages['loading data']`) }}</p>
            </div>

            <div v-show="canVisibleDetails">
                <div class="d-flex my-3 align-center">
                    <v-btn icon color="gray" class="mr-4" @click="onClickCloseDetails()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <h5 class="subtitle-1 flex">
                        {{ $t(`headers['income details']`, [detailedDate]) }}
                    </h5>
                </div>

                <div class="d-flex">
                    <div class="flex px-4">
                        <h5 class="subtitle-2 flex">{{ $t(`headers['clients by date']`) }}</h5>

                        <h5 class="body-2 text-center my-10" v-if="salesByInvite.length === 0">
                            {{ $t(`messages['no data for day']`) }}
                        </h5>

                        <v-simple-table height="300" fixed-header v-if="salesByInvite.length > 0">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">{{ $t(`tables['acquisition date']`) }}</th>
                                        <th class="text-left">{{ $t(`tables['income']`) }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="inviteDetail in salesByInvite" :key="inviteDetail.date">
                                        <td class="text-left">{{inviteDetail.date}}</td>
                                        <td class="text-left">{{inviteDetail.total | currency}}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </div>
                    <div class="flex px-4" style="border-left: 1px solid #ccc;">
                        <h5 class="subtitle-2 flex">{{ $t(`headers['places income']`) }}</h5>
                        <h5 class="body-2 text-center my-10" v-if="saleDetails.length === 0">
                            {{ $t(`messages['no data for day']`) }}
                        </h5>

                        <v-simple-table height="300" fixed-header v-if="saleDetails.length > 0">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">{{ $t(`tables['place']`) }}</th>
                                        <th class="text-left">{{ $t(`tables['income']`) }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="detail in saleDetails" :key="detail.place.ID">
                                        <td class="text-left">{{detail.place.Name | getName}}</td>
                                        <td class="text-left">{{detail.total | currency}}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </div>
                </div>

                <div class="text-right">
                    <v-btn small text @click="scrollToChart()">
                        <v-icon left>mdi-arrow-up</v-icon>
                        {{ $t(`actions['back to chart']`) }}
                    </v-btn>
                </div>
            </div>

            <div v-show="canVisibleDetailsError">
                <table-error @again="loadDetails()"/>
            </div>
        </div>
    </div>
</template>
<script>

import dayjs from "dayjs"
import { mapActions } from "vuex"
import DynamicChart from "../charts/DynamicChart"
import TableError from "../TableError"
import Format from "../../util/format"
import deepAssign from "../../util/deep-assign"
import unobserve from "../../util/unobserve"
import { i18n } from "../../plugins/i18n"

const getSalesByDay = (sales) => {
    const salesMap = sales.reduce((res, sale) => {
        const saleDate = dayjs(sale.DayCreated).format("DD.MM.YYYY")
        if (!res[saleDate]) {
            res[saleDate] = 0
        }
        res[saleDate] += Math.ceil(sale.Profit)
        return res
    }, {})

    return Object.keys(salesMap)
        .map(key => ({ date: key, total: salesMap[key] }))
        .sort((a, b) => b.total - a.total)
}

const sortByDateField = (items, key) => items.sort((a, b) => dayjs(b[key]).unix() - dayjs(a[key]).unix())

const saleAdapter = (sale) => ({
    total: Math.floor(sale.Profit || 0),
    count: sale.Count || 0,
    parent: sale.parent || 0,
})

const pointStub = () => ({
    ProfitAll: 0,
    ProfitWorksheet: 0,
    ProfitOther: 0,
    Sales: [],
    ProfitPlace: [],
})

const getOptions = () => {
    return {
        chart: {
            type: "line",
            animation: false,
        },
        xAxis: {
            crosshair: true,
            tickmarkPlacement: "on",
            type: "datetime",
            title: {
                text: i18n.t("labels.date"),
                // enabled: false,
            },
            labels: {
                formatter() {
                    return dayjs(new Date(this.value)).format("DD.MM")
                },
            },
        },
        yAxis: [
            {
                title: {
                    text: i18n.t("labels.sum"),
                },
            },
            {
                title: {
                    text: i18n.t("labels.ratio"),
                },
                opposite: true,
            },
        ],
    }
}

const fillPoints = (points, begin, end) => {
    let beginDate = dayjs(begin).startOf("day")
    const endDate = dayjs(end).endOf("day")

    const pointDate = (point) => dayjs(point.Date).startOf("day").format("DD.MM.YYYY")
    const byDate = points.reduce((res, point) => Object.assign(res, { [pointDate(point)]: point }), {})

    const result = []

    do {
        const key = beginDate.format("DD.MM.YYYY")
        if (byDate[key]) {
            result.push(byDate[key])
        } else {
            result.push({ ...pointStub(), Date: beginDate.toDate() })
        }
        beginDate = beginDate.add(1, "day")
    } while (beginDate.isBefore(endDate))

    return sortByDateField(result, "Date")
}

const fillSales = ({ begin, end }, sales, parent) => {
    let beginDate = dayjs(begin).startOf("day")
    const endDate = dayjs(end).endOf("day")

    const saleDate = (sale) => dayjs(sale.DayCreated).startOf("day").format("DD.MM.YYYY")

    const byDate = sortByDateField(sales, "DayCreated")
        .reduce((res, sale) => Object.assign(res, { [saleDate(sale)]: { ...sale, parent } }), {})

    const result = []

    do {
        const key = beginDate.format("DD.MM.YYYY")
        result.push({ ...saleAdapter(byDate[key] || { parent: key }), date: key })
        beginDate = beginDate.add(1, "day")
    } while (beginDate.isBefore(endDate))

    return result
}

export default {
    components: { DynamicChart, TableError },
    props: {
        dateRange: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
        // filter: defaultFilter(),
        // dateRange: defaultFilter(),
            loading: false,
            error: false,
            data: [],
            detailedDate: null,
            places: [],
            visibleDetails: false,
            loadingDetails: false,
            loadedDetails: false,
            errorLoadingDetails: false,
            salesByInvite: [],
            saleDetails: [],
        }
    },

    watch: {
        dateRange: {
            immediate: true,
            deep: true,
            handler() {
                this.fetch()
            },
        },
    },

    computed: {
        canVisibleError() {
            return this.error && this.loading
        },

        canVisibleData() {
            return !this.error && !this.loading
        },

        canVisibleDetails() {
            return this.loadedDetails && !this.errorLoadingDetails
        },

        canVisibleDetailsLoading() {
            return !this.loadedDetails && this.loadingDetails
        },

        canVisibleDetailsError() {
            return !this.loadingDetails && this.errorLoadingDetails
        },

        getRangeLabel() {
            const { begin, end } = this.dateRange
            return Format.dateRange(begin, end)
        },

        getSeriesOptions() {
            const { begin } = this.dateRange
            return { pointStart: +dayjs(begin), pointInterval: 24 * 3600 * 1000 }
        },

        getOptions() {
            const self = this
            return deepAssign(getOptions(), {
                chart: {
                    events: {
                        click: function(e) {
                            self.onClickPoint(this.series[0].searchPoint(e, true))
                        },
                    },
                },
                tooltip: {
                    useHTML: true,
                    style: { pointerEvents: "auto" },
                },
                plotOptions: {
                    series: {
                        point: {
                            events: {
                                click: function() {
                                    self.onClickPoint(this)
                                },
                                doubleclick() {
                                    self.onDoubleClickPoint(this)
                                },
                            },
                        },
                    },
                },
            })
        },

        getData() {
            const { begin, end } = this.dateRange

            return fillPoints(this.data, begin, end).map(point => {
                const date = dayjs(point.Date).format("DD.MM.YYYY")
                const worksheets = point.Sales.filter(s => s.IsWorksheet)
                const other = point.Sales.filter(s => !s.IsWorksheet)
                // eslint-disable-next-line no-console
                return {
                    amountWorksheets: Math.floor(point.ProfitWorksheet),
                    amountOther: Math.floor(point.ProfitOther),
                    total: Math.floor(point.ProfitOther),
                    date: date,
                    worksheets: fillSales(this.dateRange, worksheets, date),
                    worksheetDates: worksheets.map(w => dayjs(w.DayCreated).startOf("day").format("DD.MM.YYYY")),
                    worksheetsCount: worksheets.length,
                    others: fillSales(this.dateRange, other, date),
                    othersCount: other.length,
                    salesByInvite: getSalesByDay(point.Sales),
                    placesTotal: point.ProfitPlace
                        .map(item => ({ place: item.Place, total: Math.ceil(item.Profit) }))
                        .sort((a, b) => b.total - a.total),
                }
            })
        },

        getLine() {
            const { getData } = this
            const { begin, end } = this.dateRange
            return {
                type: "spline",
                name: this.$t(`rudiments['total']`),
                data: getData.reverse().map(point => ({ y: Math.ceil(point.total), ...point })),
                marker: { lineWidth: 2, fillColor: "red" },
                color: "rgba(255, 0, 0, 0.7)",
                key: `${begin}${end}`,
                keepAlive: true,
                ...this.getSeriesOptions,
                tooltip: {
                    headerFormat: "",
                    pointFormatter: function() {
                        const date = this.date
                        return `<span class="income-report__tooltip">
                        <b>${date}</b>
                        <br/>${i18n.t("formatted.total", [Format.currency(this.total)])}<br/>
                        <br/>${i18n.t("formatted.salesSheets", [this.worksheetsCount, this.amountWorksheets])}
                        <br/>${i18n.t("formatted.othersCount", [this.othersCount, this.amountOther])}<br/>
                    </span>`
                    },
                },
            }
        },

        getWorksheetBars() {
            const { getData, detailedDate } = this
            const target = getData.find(point => point.date === detailedDate)

            if (!target) {
                return null
            }

            return {
                type: "column",
                name: this.$t(`rudiments['worksheets sales']`),
                color: "#2abedd",
                yAxis: 1,
                data: target.worksheets.map(w => ({ y: w.total, ...w })),
                ...this.getSeriesOptions,
                tooltip: {
                    headerFormat: "",
                    pointFormatter: function() {
                        return `<div>
                        ${i18n.t("formatted.sales")}<br/>
                        ${i18n.t("formatted.dateSold", [detailedDate])}<br/>
                        ${i18n.t("formatted.dateCreated", [this.date])}<br/>
                        <br/>${i18n.t("formatted.total", [Format.currency(this.total)])}
                        <br/>${i18n.t("formatted.amount", [this.count])}
                    </div>`
                    },
                },
            }
        },

        getOtherBars() {
            const { getData, detailedDate } = this
            const target = getData.find(point => point.date === detailedDate)

            if (!target) {
                return null
            }

            return {
                type: "column",
                name: this.$t(`rudiments['other sales']`),
                color: "#69cd4b",
                yAxis: 1,
                data: target.others.map(w => ({ y: w.total, ...w })),
                ...this.getSeriesOptions,
                tooltip: {
                    headerFormat: "",
                    pointFormatter: function() {
                        return `<div>
                        ${i18n.t("formatted.others")}<br/>
                        ${i18n.t("formatted.dateSold", [detailedDate])}<br/>
                        ${i18n.t("formatted.dateUploaded", [this.date])}<br/>
                        <br/>${i18n.t("formatted.total", [Format.currency(this.total)])}
                        <br/>${i18n.t("formatted.amount", [this.count])}
                    </div>`
                    },
                },
            }
        },

        getSeries() {
            return [
                this.getWorksheetBars,
                this.getOtherBars,
                this.getLine,
            ].filter(bar => !!bar)
        },
    },

    created() {
        this.fetch()
    },

    mounted() {
        // window.onClickIncomeDetails = (...args) => this.onClickIncomeDetails(...args)
    },

    methods: {
        ...mapActions("statistic", ["getProfitDetails"]),
        ...mapActions("place", ["getPlaces"]),
        onAgainFetch() {
            this.fetch()
        },

        fetch() {
            this.clearDetailsState()
            this.loading = true
            this.getProfitDetails(this.dateRange).then(res => {
                this.data = res
            }).catch(() => {
                this.error = true
            }).finally(() => {
                this.loading = false
            })
        },

        clearDetailsState() {
            this.visibleDetails = false
            this.loadingDetails = false
            this.loadedDetails = false
            this.errorLoadingDetails = false
        },

        // eslint-disable-next-line no-unused-vars
        onClickPoint(point) {
            if (point.parent) {
                return
            }

            this.detailedDate = point.date
            this.onClickIncomeDetails(point.date)
        },

        loadDetails() {
            this.visibleDetails = true
            this.loadingDetails = true
            this.loadedDetails = false
            this.errorLoadingDetails = false
            this.$nextTick(this.scrollToDetails)

            Promise.all([
                this.fetchDetails(),
                this.injectInviteSales(),
            ]).then(([details]) => {
                this.saleDetails = details
                this.loadedDetails = true
            }).catch(() => {
                this.errorLoadingDetails = true
            }).finally(() => {
                this.loadingDetails = false
                this.$nextTick(this.scrollToDetails)
            })
        },

        scrollToDetails() {
            this.$refs.details.scrollIntoView({ behavior: "smooth", block: "center" })
        },

        scrollToChart() {
            this.$refs.chart.scrollIntoView({ behavior: "smooth", block: "center" })
        },

        onClickIncomeDetails(date) {
            this.detailedDate = date
            this.loadDetails()
        },

        fetchDetails() {
            const targetDay = this.getData.find(d => d.date === this.detailedDate) || {}
            const placeSales = unobserve(targetDay.placesTotal || [])
            const placeIds = placeSales.map(sale => sale.place)
            return this.getPlaces(placeIds)
                .then((places) => {
                    return placeSales.map((place, i) => Object.assign(placeSales[i], { place: places[i] }))
                        .filter(item => !!item.place)
                })
        },

        injectInviteSales() {
            const targetDay = this.getData.find(d => d.date === this.detailedDate) || {}
            this.salesByInvite = targetDay.salesByInvite || []
        },

        clearDetailedDate() {
            this.detailedDate = null
        },

        onClickCloseDetails() {
            this.visibleDetails = false
        },
    },
}
</script>

<style lang="stylus">
    .income-report__details {
        font-size: 14px;
        text-decoration underline
        position relative
        z-index 22
        margin-top 12px
        display block
        font-style italic

        &:hover {
            color #4a90e2
            cursor pointer
        }
    }
</style>
