<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <h3 class="title">{{ $t(`headers['sheets created']`) }}</h3>
        <div class="d-flex align-center justify-center flex-column py-10 my-10" v-if="loading">
            <v-progress-circular
                :size="50"
                color="blue-grey darken-4"
                indeterminate/>
            <p class="subtitle-1 mt-4">{{ $t(`messages['loading data']`) }}</p>
        </div>

        <div v-show="!loading" ref="chart">
            <h3 class="subtitle-1 font-italic">
                {{ $t(`headers['sheets created ranged']`) }} <b>{{getTotalWorksheets}}</b>
            </h3>

            <dynamic-chart :options="getOptions" theme="area" v-model="getSeries"/>
        </div>

        <div v-show="visibleDetails" ref="details">
            <div class="d-flex align-center justify-center flex-column py-10 my-10" v-if="loadingDetails">
                <v-progress-circular
                    :size="50"
                    color="blue-grey darken-4"
                    indeterminate/>
                <p class="subtitle-1 mt-4">{{ $t(`messages['loading data']`) }}</p>
            </div>

            <div v-if="!loadingDetails">
                <div class="d-flex my-3 align-center">
                    <v-btn icon color="gray" class="mr-4" @click="onClickCloseDetails()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <h5 class="subtitle-1 flex">
                        {{ $t(`headers['sheets details']`, [filteredDate])}}
                    </h5>
                </div>

                <h5 class="subtitle-1 flex text-center" v-show="!details.length">
                    {{ $t("messages['empty daily worksheets']", [filteredDate])}}
                </h5>

                <v-simple-table fixed-header height="300" v-show="details.length">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">{{ $t(`tables['place']`) }}</th>
                                <th class="text-left">{{ $t(`headers['sheets created']`) }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="detail in details" :key="detail.Place.ID">
                                <td>
                                    <b>{{detail.Place.Name | getName}}</b>
                                </td>
                                <td>
                                    {{ detail.Count }}
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>

                <div class="text-right">
                    <v-btn small text @click="scrollToChart()">
                        <v-icon left>mdi-arrow-up</v-icon>
                        {{ $t(`actions['back to chart']`) }}
                    </v-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex"
import dayjs from "dayjs"
import DynamicChart from "../charts/DynamicChart"
import { i18n } from "../../plugins/i18n"
import * as Format from "../../util/format"

const getOptions = (listeners = {}) => {
    return {
        chart: {
            events: {
                click: function (e) {
                    listeners.clickCross(e, this)
                },
            },
        },
        yAxis: {
            title: {
                text: i18n.t(`rudiments.created`),
            },
        },
        tooltip: {
            pointFormat: "<span style=\"color:{series.color}\">{series.name}</span>: <b>{point.percentage:.1f}%</b> ({point.y:,.0f})<br/>",
            split: true,
            formatter: function () {
                const s = []
                s.push(dayjs(new Date(this.x)).format("DD.MM"))
                this.points.forEach((point) => {
                    s.push(`<b>${point.series.name}: ${point.y}</b>(${Math.round(point.percentage * 100) / 100}%)<br><span style="font-size: 12px">${i18n.t("actions.push")}</span>`)
                })
                return s
            },
        },
        plotOptions: {
            area: {
                lineColor: "#ffffff",
                lineWidth: 1,
                marker: {
                    lineWidth: 1,
                    lineColor: "#ffffff",
                },
                accessibility: {
                    pointDescriptionFormatter: function (point) {
                        function round(x) {
                            return Math.round(x * 100) / 100
                        }

                        return (point.index + 1) + ", " + point.category + ", " +
                            point.y + ", " + round(point.percentage) + "%, " +
                            point.series.name
                    },
                },
                events: {
                    click: function (e) {
                        listeners.clickPoint(e)
                    },
                },
            },
            series: {
                stacking: "normal",
            },
        },
        xAxis: {
            type: "datetime",
            tickmarkPlacement: "on",
            crosshair: true,
            title: {
                enabled: false,
            },
            labels: {
                formatter() {
                    return dayjs(new Date(this.value)).format("DD.MM")
                },
            },
        },
    }
}

export default {
    components: { DynamicChart },
    props: {
        dateRange: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            loading: false,
            visibleDetails: false,
            loadingDetails: false,
            detailsDate: null,
            minHeight: 0,
            data: [],
            details: [],
        }
    },
    computed: {

        filteredDate() {
            return Format.formatDateTime(this.detailsDate, "DD.MM.YYYY")
        },
        getSeries() {
            const { data, dateRange } = this
            const createdWorksheets = data.reduce((res, day) => {
                const date = dayjs(day.d).format("DD.MM")
                res.push({ date: date, rawDate: day.d, label: date, y: day.CreatedWorksheet })
                return res
            }, [])

            const seriesOptions = { pointStart: +dateRange.begin, pointInterval: 24 * 3600 * 1000 }

            return [
                {
                    name: this.$t(`headers['sheets created']`),
                    data: createdWorksheets,
                    color: "rgba(255, 0, 0, 0.7)", ...seriesOptions,
                },
            ]
        },

        getOptions() {
            return getOptions({
                clickPoint: e => this.onClickPoint(e),
                clickCross: (e, chart) => this.onClickPoint(chart.series[0].searchPoint(e, true)),
            })
        },

        getTotalWorksheets() {
            const { data } = this
            return data.reduce((res, day) => res + day.CreatedWorksheet, 0)
        },
    },
    watch: {
        dateRange: {
            immediate: true,
            deep: true,
            handler() {
                this.fetchData()
            },
        },
    },
    methods: {
        ...mapActions("statistic", ["getVisitsSource", "getWorksheetsCreated"]),
        fetchData() {
            this.loading = true
            this.visibleDetails = false
            this.getVisitsSource(this.dateRange).then(res => {
                this.data = res
            }).finally(() => {
                this.loading = false
            })
        },

        // eslint-disable-next-line no-unused-vars
        onClickPoint(data) {
            const date = data.rawDate ? data.rawDate : data.point.rawDate
            this.detailsDate = dayjs(date)
            this.visibleDetails = true
            this.$nextTick(this.scrollToDetails)
            this.loadDetails()
        },

        loadDetails() {
            this.loadingDetails = true
            this.fetchDetails().finally(() => {
                this.loadingDetails = false
                this.$nextTick(this.scrollToDetails)
            })
        },

        fetchDetails() {
            const begin = this.detailsDate.startOf("day")
            const end = this.detailsDate.endOf("day")

            return this.getWorksheetsCreated({ begin, end }).then(res => {
                this.details = res[0].places.filter(d => !!d.Place)
            })


        // eslint-disable-next-line no-console
        // console.log("this.detailsDate ", this.detailsDate)
        // return new Promise((resolve) => {
        //   setTimeout(resolve, 5000, [])
        // })
        },

        scrollToDetails() {
            this.$refs.details.scrollIntoView({ behavior: "smooth", block: "center" })
        },

        scrollToChart() {
            this.$refs.chart.scrollIntoView({ behavior: "smooth", block: "center" })
        },

        onClickCloseDetails() {
            this.visibleDetails = false
        },
    }
}
</script>
