import { singleIdempotenceFetch } from "../util/idempotence-fetch"
import { GET_JSON } from "../util/resource"

export const namespaced = true

export const actions = {
    getVisitsSource(ctx, range) {
        const key = `visits-${JSON.stringify(range)}`
        const fetch = () => GET_JSON("/places/visits", range).then(res => res.collection)
        return singleIdempotenceFetch(key, fetch)
    },

    getProfitDetails(ctx, attrs) {
        return GET_JSON("/places/profit", attrs)
            .then(res => res.collection || [])
    },

    getWorksheetsCreated({ dispatch }, attrs) {
        return GET_JSON("/places/worksheets", attrs)
            .then(res => res.collection || [])
            .then((res) => dispatch("injectWorksheetsCreated", res))
    },

    injectWorksheetsCreated({ dispatch }, data) {
        return Promise.all(
            data.map(item => dispatch("injectWorksheetCreated", item)),
        ).then((result) => result.map((item, i) => ({ places: item, date: data[i].Day })))
    },

    injectWorksheetCreated({ dispatch }, data) {
        const worksheets = data.Worksheets || []

        const placeIds = worksheets.map(item => item.Place)
        return dispatch("place/getPlaces", placeIds, { root: true })
            .then((places) => worksheets.map((item, i) => {
                return Object.assign(item, { Place: places[i] })
            }))
    }
}
