const isObject = value => value && typeof value === 'object' && !Array.isArray(value);

export default function deepAssign(target, source) {
    const output = Object.assign({}, target);

    if (!isObject(target) || !isObject(source)) {
        return output;
    }

    Object.keys(source).forEach(key => {
        if (!isObject(source[key])) {
            Object.assign(output, { [key]: source[key] });
            return;
        }

        if (key in target) {
            output[key] = deepAssign(target[key], source[key]);
        } else {
            Object.assign(output, { [key]: source[key] });
        }
    });

    return output
}
