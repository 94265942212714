import Vue from "vue"
import VueRouter from "vue-router"
import YaMetrika from "./services/YaMetrika"
import store from "./store"
import ErrorLayout from "./layouts/error"
import LoginPage from "./pages/login"
import StatisticPage from "./pages/statistics_old"

Vue.use(VueRouter)

const mode = "history"

const interopDefault = promise => promise.then(m => m.default || m)

const routes = [
    {
        path: "/login",
        meta: { authorized: false },
        component: LoginPage,
    },
    {
        path: "/forbidden",
        meta: { authorized: false },
        component: () => import("./pages/forbidden"),
    },
    {
        path: "/",
        component: () => import("./pages/dashboard"),
    },
    {
        path: "/statistics",
        component: StatisticPage,
    },
    {
        path: "/places",
        component: () => import("./pages/places"),
    },
    {
        path: "/places/create",
        component: () => import("./pages/create-place"),
    },
    {
        path: "/places/:id",
        component: () => import("./pages/place"),
    },
    {
        path: "/photographers/:id",
        component: () => import("./pages/photographer"),
    },
    {
        path: "/upload",
        component: () => import("./pages/upload"),
    },
    {
        path: "/directory",
        component: () => import("./pages/directory"),
    },
    {
        path: "/session/:id",
        component: () => import("./pages/session"),
    },
    {
        path: "/photoset/:id",
        component: () => import("./pages/photoset"),
    },
    {
        path: "/training",
        component: () => import("./pages/training"),
    },
    {
        path: "/profile",
        component: () => import("./pages/profile"),
    },
    {
        path: "/tickets",
        component: () => import("./pages/tickets"),
    },
    {
        path: "*",
        component: ErrorLayout,
        name: "error",
        props: route => ({
            error: route.params.error || {
                statusCode: 404,
                message: "page not found",
            },
        }),
    },
]

const router = new VueRouter({
    mode,
    routes,
})

// eslint-disable-next-line no-unused-vars
const checkAuthorization = (to, from, next) => {
    const availableUnauthorized = to.meta.authorized === false
    const isAuthorized = store.getters["common/isAuthorized"]
    if (!isAuthorized) {
        return next(availableUnauthorized ? undefined : "/login")
    }
    next()
}

const updateLoading = val => (from, to, next) =>
    store.commit("common/setLoadingPage", val, { root: true }) ||
  (next && next())

const getFetchChain = route => {
    const matched = route.matched || []
    const awaits = matched
        .reduce((res, match) => {
            const components = match.components || {}
            return res.concat(Object.values(components))
        }, [])
        .map(component => {
            if (typeof component === "function") {
                return interopDefault(component())
            }
            return component
        })

    return Promise.all(awaits)
        .then(components => {
            return components.map(component => (component || {}).fetch)
        })
        .then(fetches => fetches.filter(fetch => typeof fetch === "function"))
}

router.beforeEach(updateLoading(true))
router.beforeEach(checkAuthorization)

router.beforeEach((to, from, nextRoute) => {
    const toPath = to.fullPath.replace(to.hash, "")
    const fromPath = from.fullPath.replace(from.hash, "")

    if (toPath === fromPath) {
        return nextRoute()
    }

    let ignorePromise = false
    const redirect = path => {
        ignorePromise = true
        nextRoute(path)
        if (path === from.path) {
            updateLoading(false)()
        }
    }
    const context = { ...to, redirect, store }

    const chainFetch = chain => {
        return new Promise((resolve, reject) => {
            const next = () => {
                const fn = chain.shift()
                if (!fn) {
                    return resolve()
                }

                const error = data => reject(data)

                Promise.resolve(fn({ ...context, error }))
                    .then(next)
                    .catch(reject)
            }

            next()
        })
    }

    getFetchChain(to)
        .then(chainFetch)
        .then(() => ignorePromise === false && nextRoute())
        .catch(error => {
            ignorePromise === false &&
      nextRoute({
          name: "error",
          params: { error },
      })
        })
})

router.afterEach(updateLoading(false))
router.afterEach(() => YaMetrika.hit())

export default router
