export default (keys, callee) => {
    const indexes = {}
    let index = 0

    const unique = keys.filter((key, i) => {
        if (keys.indexOf(key) === i) {
            indexes[key] = index
            index++
            return true
        }
    })

    const awaits = unique.map(key => callee(key))

    return Promise.all(awaits).then(
        results => keys.map(key => results[indexes[key]]),
    )
}

const singleFetches = {}
export const singleIdempotenceFetch = (key, fetch) => {
    if (singleFetches[key]) {
        return singleFetches[key]
    }

    singleFetches[key] = fetch().finally(() => {
        singleFetches[key] = null
    })

    return singleFetches[key]
}
