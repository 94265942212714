import Vue from "vue"
import { getName, getDescription, getUserName, getMedia, formatDateTime, currency, roleName, getMB } from "./util/format"

Vue.filter("getDescription", getDescription)
Vue.filter("currency", currency)
Vue.filter("getName", (obj) => {
    if ("First" in obj && "Last" in obj) {
        return getUserName(obj)
    }
    return getName(obj)
})
Vue.filter("getMedia", getMedia)
Vue.filter("roleName", roleName)

Vue.filter('getMB', getMB)

Vue.filter("getExtension", (name) => (name.split(".")[1].toUpperCase()))

Vue.filter("unixDateTime", (date, format) => formatDateTime(new Date(date * 1000), format))
Vue.filter("date", (date, format) => formatDateTime(date, format))
Vue.filter("webLink", url => `https://storemood.com${url}`)
Vue.filter("fixedNumber", value => {
    if (typeof value !== "number" || value % 1 === 0) {
        return value
    }

    return value.toFixed(2)
})
