<template>
    <div class="place-filter">
        <base-filter v-model="model" @submit="onSubmit()">
            <template slot="autocomplete">
                <place-autocomplete :allow="allowPlaces" :multiple="multiple" v-model="selected"/>
            </template>
        </base-filter>
    </div>
</template>

<script>
import Filter from "../Filter"
import PlaceAutocomplete from "./Autocomplete"

export default {
    components: { BaseFilter: Filter, PlaceAutocomplete },
    name: "PlaceFilter",
    props: {
        value: { type: Object, default: () => ({}) },
        multiple: { type: Boolean, default: true },
        places: { type: Array, default: () => []},
        allowPlaces: { type: Array, default: null },
    },

    data() {
        const model = Object.assign({ places: []}, this.value)
        return {
            selected: model.places || [],
            model: model,
        }
    },

    watch: {
        selected: {
            handler() {
                this.$emit("input", Object.assign(this.model, { places: this.selected }))
            },
            deep: true,
        },

        model: {
            handler(val) {
                this.$emit("input", Object.assign(val, { places: this.selected }))
            },
            deep: true,
        },

        value: {
            handler(value) {
                this.model = value
                this.selected = value.places || []
            },
            deep: true,
        },
    },
    methods: {
        onSubmit() {
            this.$emit("submit")
        },
    },
}
</script>
