import { GET_JSON } from "@/util/resource"
import dayjs from "dayjs"

const MASTER_EVENTS = "default.`github.com/themakers/storemood-master/events`"
const WEB_EVENTS = "storemood_web_production"

const tables = { master: MASTER_EVENTS, web: WEB_EVENTS }
const timeZoneOffset = Math.abs(new Date().getTimezoneOffset() * 60)

export const namespaced = true

export const actions = {
    count(ctx, { from = "master", where = []}) {
        return GET_JSON("/execute/count", { from: tables[from], where })
    },

    madePhotosCount({ dispatch }, { where, begin, end }) {
        const $begin = dayjs(begin)
        const $end = dayjs(end)
        where.push("name,=,InfoUploadPhotoEvent")
        where.push(`ctx_ExifTime,>,toInt64(${$begin.unix() + timeZoneOffset})`)
        where.push(`ctx_ExifTime,<,toInt64(${$end.unix() + timeZoneOffset})`)

        return dispatch("count", { where: where.join("+") })
    },
}
