import Connection from "@/plugins/connection"
import dayjs from "dayjs"
import Format from "../util/format"

// eslint-disable-next-line no-unused-vars
const { SearchPR, Find } = Connection.getService("Photoreport").mapStreams(["SearchPR", "Find"])
const {
    AddPhoto,
    Create,
    GetByID,
    Delete,
    UpdateFeatures,
    SetName,
    DeletePhotos,
    SetBulkOnlyAndHidden,
    SetPrepay,
    SetCost,
    DeletePhone,
    DeleteEmail,
} = Connection.getService("Photoreport").mapRequests([
    "AddPhoto",
    "Create",
    "GetByID",
    "Delete",
    "UpdateFeatures",
    "SetName",
    "DeletePhotos",
    "SetBulkOnlyAndHidden",
    "SetPrepay",
    "SetCost",
    "DeletePhone",
    "DeleteEmail",
])

export const namespaced = true
export const state = () => ({
    photoreport: null,
})

export const getters = {
    getPhotoreport(state) {
        return state.photoreport
    },
}

export const mutations = {
    setPhotoreport(state, report) {
        state.photoreport = report
    },
}

export const actions = {
    inject({ dispatch }, photoreports) {
        return Promise.all(
            photoreports.map(p => dispatch("injectPhotoreport", p)),
        )
    },

    injectPhotoreport({ dispatch }, photoreport) {
        const { PlaceID, PhotoIDs } = photoreport
        if (photoreport.Cost === "0") {
            photoreport.Cost = ""
        }
        return Promise.all([
            dispatch("place/getById", PlaceID, { root: true }),
            dispatch("photos/getByIds", PhotoIDs, { root: true }),
        ]).then(([Place, Photos]) => {
            return Object.assign(photoreport, { Place, Photos: Photos })
        })
    },

    // eslint-disable-next-line no-unused-vars
    search({ dispatch }, { params, inject = false }) {
        return SearchPR(params).storeData()
            .then(res => inject ? dispatch("inject", res) : res)
    },

    addPhoto(ctx, { reportId, photoId }) {
        return AddPhoto({ ID: reportId, PhotoID: photoId })
    },

    getById(ctx, id) {
        return GetByID({ ID: id })
    },

    storePhotoreport({ dispatch, commit }, id) {
        return dispatch("getById", id)
            .then(res => dispatch("injectPhotoreport", res))
            .then(report => {
                commit("setPhotoreport", report)
            })
    },

    deletePhotos(ctx, payload) {
        return DeletePhotos(payload)
    },

    updateFeatures(ctx, payload) {
        return UpdateFeatures(payload)
    },

    setName(ctx, payload) {
        return SetName(payload)
    },

    update({ dispatch }, payload) {
        const { ID, Name, Features } = payload
        return Promise.all([
            dispatch("setName", { ID, NewName: Name }),
            dispatch("updateFeatures", { ID, Features }),
        ])
    },

    updateSettings(ctx, payload) {
        const { ID, BulkOnly, Hidden, Cost, Prepaid } = payload
        return Promise.all([
            SetBulkOnlyAndHidden({ ID, BulkOnly, Hidden }),
            SetPrepay({ ID, Prepay: Prepaid }),
            SetCost({ ID, Cost }),
        ])
    },

    // eslint-disable-next-line no-unused-vars
    addPhotos({ dispatch }, { reportId, photosIds }) {
        return Promise.all(
            photosIds.map(photoId => dispatch("addPhoto", { reportId, photoId })),
        )
    },

    create(ctx, params) {
        return Create(params)
    },

    remove(ctx, id) {
        return Delete({ ID: id })
    },

    removeMany({ dispatch }, ids) {
        return Promise.all(
            ids.map(id => dispatch("remove", id)),
        )
    },

    move({ dispatch }, { reportId, photosIds }) {
        return dispatch("addPhotos", { reportId, photosIds })
            .then(() => dispatch("removeMany", photosIds))
    },

    find(ctx, id) {
        return Find({
            PlaceID: id,
        }).storeData()
    },

    removePhone(ctx, attrs) {
        return DeletePhone(attrs)
    },

    removeEmail(ctx, attrs) {
        return DeleteEmail(attrs)
    },

    removePhones({ dispatch }, { phones, id }) {
        return Promise.all(
            phones.map(p => dispatch("removePhone", { ID: id, Phone: p })),
        )
    },

    removeEmails({ dispatch }, { phones, id }) {
        return Promise.all(
            phones.map(e => dispatch("removeEmail", { ID: id, Email: e })),
        )
    },

    createByDate({ dispatch, rootGetters }, { date, placeId }) {
        const $date = dayjs(date)
        const formattedDate = $date.format("DD.MM.YYYY")
        const locale = rootGetters["common/getLocale"].toUpperCase();

        const photosRequest = {
            StartTime: $date.startOf("day").unix(),
            EndTime: $date.endOf("day").unix(),
            PlaceID: placeId,
        }

        return Promise.all([
            dispatch("photos/getInRange", photosRequest, { root: true }),
            dispatch("place/getById", placeId, { root: true }),
        ]).then(([photos, place]) => {
            const placeName = Format.getName(place.Name, place.ID)
            return {
                PlaceID: place.ID,
                PhotoIDs: photos.map(p => p.ID),
                Name: { [locale]: `${placeName} - ${formattedDate}` },
            }
        }).then(attrs => dispatch("create", attrs))
    },
}
