const fromJSON = (params) => {
    const payload = new FormData();
    // eslint-disable-next-line no-prototype-builtins
    for (const i in params) if (params.hasOwnProperty(i)) {
        if (Array.isArray(params[i])) {
            params[i].forEach(p => payload.append(`${i}[]`, p));
        } else {
            payload.append(i, params[i]);
        }
    }
    return payload;
};


export default { fromJSON };
