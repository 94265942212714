import Vue from "vue"
import VueMeta from "vue-meta"
import PortalVue from "portal-vue"
import DatetimePicker from "vuetify-datetime-picker/src"
import { ResourceError } from "storemood-connection"
import App from "./App.vue"
import router from "./router"
import vuetify from "./plugins/vuetify"
import { i18n } from "./plugins/i18n"
import store from "./store"
import Resource from "./util/resource"
import Connection from "./plugins/connection"
import "./filters"
import "./plugins/dialog"
import dayjs from "dayjs"
import "dayjs/locale/ru"

import "./assets/datetimepicker.styl"

Resource.setDefaults({ base: "https://manage.storemood.com/api" })

dayjs.locale("ru")

// Install date-time picker
DatetimePicker(Vue)
Vue.use(PortalVue)
Vue.use(VueMeta, { keyName: "head", refreshOnceOnNavigation: false })

Vue.config.productionTip = false

// eslint-disable-next-line no-unused-vars
const mountApp = () => {
    const isAuthorized = store.getters["common/isAuthorized"]

    if (!isAuthorized) {
        router.replace("/login").catch(noop)
    }

    // eslint-disable-next-line no-unused-vars
    const app = new Vue({
        vuetify,
        i18n,
        router,
        store,
        render: h => h(App),
    })

    store.$getService = (...args) => Connection.getService(...args)
    store.$router = router

    const onReady = () => {
        enableSupport(store)
        app.$mount("#app")
    }

    app.$router.onReady(onReady, onReady)
}

const onConnectionError = (e) => {
    new ResourceError(e).ifCode("no user", () => {
        store.dispatch("common/logout")
        router.push(`/login?next=${router.currentRoute.fullPath}`).catch(noop)
    })
}

store.dispatch("common/startApp", null, { root: true }).finally(mountApp)
Connection.on("error", onConnectionError)

const enableSupport = (store) => {
    const onChange = (val) => {
        if (val) {
            // eslint-disable-next-line no-console
            store.dispatch("support/subscribe", {
                isAdmin: store.getters["common/isPlaceAdmin"],
                isSuperuser: store.getters["common/isJesus"],
                userId: store.getters["common/getUserId"],
                pageUrl: "/tickets",
                payload: {
                    Status: [0, 1]
                }
            })
        } else {
            store.dispatch("support/unsubscribe")
        }
    }

    store.watch(() => store.getters["common/isAuthorized"], onChange, { immediate: true })
    onChange(store.getters["common/isAuthorized"])
}

const noop = () => {
    // Do nothing
}
