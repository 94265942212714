import Connection from "../plugins/connection"
import { GET_JSON } from "../util/resource"

const { FindPhotographers } = Connection.getService("User").mapStreams(["FindPhotographers"])
const { GetByID } = Connection.getService("User").mapRequests(["GetByID"])

const responseAdapter = (response, photographers) => {
    response.collection = response.collection
        .map((item, i) => ({ statistic: item, photographer: photographers[i] }))
        .filter(item => item.photographer !== null)
    return response
}

export const namespaced = true

export const state = () => ({
    pageData: {
        photographer: null,
        statistic: null,
        place: null,
    },
})

export const getters = {
    getPageData(state) {
        return state.pageData
    },
}

export const mutations = {
    setPageData(state, { photographer, statistic, place }) {
        state.pageData.photographer = photographer
        state.pageData.statistic = statistic
        state.pageData.place = place
    },
}

export const actions = {
    fetchById({ dispatch }, { params, id }) {
        params.begin = params.begin || 0
        return dispatch("fetchCollection", Object.assign({}, params, { ids: [id]}))
            .then(res => res.collection[0])
    },

    findByQuery(ctx, { query, params = {} }) {
        return FindPhotographers(Object.assign({ Query: query, limit: 20 }, params))
            .storeData()
    },

    fetchCollection({ dispatch }, params) {
        params.begin = params.begin || 0
        return GET_JSON("/photographers", params)
            .then(res => dispatch("inject", res))
    },

    fetchCollectionByPlace({ dispatch }, { placeId, params }) {
        params.begin = params.begin || 0
        return GET_JSON(`/places/${encodeURIComponent(placeId)}/photographers`, params)
            .then(res => dispatch("inject", res))
    },

    inject(ctx, statistic) {
        statistic.collection = statistic.collection || []
        statistic.count = statistic.count || 0
        const photographerIds = statistic.collection.map(i => i.photographerId)
        return ctx.dispatch("getPhotographers", photographerIds)
            .then(places => responseAdapter(statistic, places))
    },

    getPhotographers(ctx, photographerIds) {
        return Promise.all(
            photographerIds.map(id => GetByID({ ID: id }).catch(() => null)),
        )
    },

    fetchPage({ dispatch, commit }, { id, place, filter }) {
        return Promise.all([
            dispatch("fetchById", { id, params: filter }),
            dispatch("place/getById", place, { root: true }).catch(() => null),
        ]).then(([{ photographer, statistic }, place]) => {
            commit("setPageData", { photographer, statistic, place })
        })
    },
}
