import Vue from "vue"
import Vuex from "vuex"
import config from "config"

Vue.use(Vuex)

import * as common from "./common"
import * as top from "./top"
import * as place from "./place"
import * as photographer from "./photographer"
import * as user from "./user"
import * as directory from "./directory"
import * as photogroup from "./photogroup"
import * as photos from "./photos"
import * as promo from "./promo"
import * as execute from "./execute"
import * as photoreport from "./photoreport"
import * as statistic from "./statistic"

// Support
import * as support from 'storemoodify/lib/support/support.vuex'

export default new Vuex.Store({
    state: {
        locale: "ru",
        locales: config.locales,
    },
    getters: {
        getLocales(state) {
            return state.locales
        },
        getLocale(state) {
            return state.locale
        },
    },
    mutations: {
        setLocale(state, locale) {
            state.locale = locale
        },
    },
    actions:{
        setLocale({ commit }, locale) {
            commit('setLocale', locale)
        }
    },
    modules: {
        common,
        place,
        photographer,
        photoreport,
        user,
        directory,
        photogroup,
        photos,
        promo,
        execute,
        top,
        statistic,
        support
    },
})
