import Connection from "../plugins/connection"

const { GetInTimeRange } = Connection.getService("Photo").mapStreams(["GetInTimeRange"])
const { Delete, GetPhotos } = Connection.getService("Photo").mapRequests(["Delete", "GetPhotos"])

export const namespaced = true
export const actions = {
    getInRange({ dispatch }, attrs) {
        return GetInTimeRange(attrs)
            .storeData()
            .then(photos => dispatch("inject", photos))
    },

    getByIds(ctx, ids) {
        if (ids.length === 0) {
            return Promise.resolve([])
        }

        return GetPhotos({ ID: ids })
            .then(photos => photos.Result)
            .then(photos => ctx.dispatch("inject", photos))
    },

    remove(ctx, id) {
        return Delete({ ID: id })
    },

    removeMany({ dispatch }, ids) {
        return Promise.all(
            ids.map(id => dispatch("remove", id)),
        )
    },

    inject({ dispatch }, photos) {
        const photographers = photos.map(p => p.PhotographerID)
        return dispatch("user/getByIds", photographers, { root: true })
            .then((res) => {
                return photos.map((photo, i) => Object.assign(photo, { Photographer: res[i] }))
            })
    },
}
