import dayjs from "dayjs"
import config from "config"
import { i18n } from "../plugins/i18n"

const ROLES = {
    seller: i18n.t(`roles['seller']`),
    admin: i18n.t(`roles['admin']`),
    photographer: i18n.t(`roles['photographer']`),
    retoucher: i18n.t(`roles['retoucher']`),
}


export const getName = (nameLike = {}, def = "Unnamed") => {
    const name = nameLike.RU || nameLike.EN || nameLike.TR || ""
    if (name.trim() === "") {
        return def
    }
    return name
}

export const getMB = (size) => (`${(size / 1048576).toFixed(2)} MB`)

export const getUserName = (nameLike = {}) => `${getName(nameLike.First, "Anonymous")} ${getName(nameLike.Last, "")}`

export const getDescription = getName

export const getMedia = (value, context = "user") => {
    let placeholder = ""
    if (context === "place") {
        placeholder = "/images/place-avatar.svg"
    } else if (context === "wallpaper") {
        placeholder = "/images/wallpaper.jpg"
    } else if (context === "default") {
    // Do nothing
    } else {
        placeholder = "/images/avatar.svg"
    }
    return value ? `${config.storage}${value}` : placeholder
}

export const formatDateTime = (date, format = "DD.MM.YYYY HH:mm") => dayjs(date).format(format)

export const dateRange = (b, e, format = "DD.MM.YYYY HH:mm") => {
    const begin = dayjs(b)
    const now = dayjs().startOf("minute")
    const end = dayjs(e)
    const today = dayjs().startOf("day")
    const yesterday = today.clone().subtract(1, "day")

    if (!b && !e) {
        return i18n.t(`ranges['all time']`)
    }

    if (!begin.isValid() && end.isValid()) {
        return i18n.t(`ranges['to']`, [end.format(format)])
    }

    if (begin.isValid() && !end.isValid()) {
        return i18n.t(`ranges['from']`, [begin.format(format)])
    }

    if (
        begin.isSame(today) &&
    (end.isSame(today.endOf("day")) || end.startOf("minute").isSame(now))
    ) {
        return i18n.t(`ranges['for today']`)
    }

    if (begin.isSame(yesterday) && end.isSame(yesterday.endOf("day"))) {
        return i18n.t(`ranges['for yesterday']`)
    }

    return i18n.t(`ranges['period']`, [begin.format(format), end.format(format)])
}

export const currencyAsNumber = (value) => {
    let val = value

    if (parseInt(val) !== val) {
        const rounded = Math.floor(val * 100) / 100
        val = parseFloat(rounded.toFixed(2))
    }

    return val
}

export const currency = value => {
    const formatted = currencyAsNumber(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    return `${formatted}Р`
}

export const roleName = (role) => ROLES[role]

export default { getName, getDescription, getUserName, getMedia, currencyAsNumber, currency, roleName, dateRange }
