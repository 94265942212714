import dayjs from "dayjs"
import config from "config"
import Connection from "../plugins/connection"
import { GET_JSON, POST_FORM } from "../util/resource"
import idempotenceFetch from "../util/idempotence-fetch"
import storeCache from "../util/store-cache"
import FormData from "../util/form-data"

const cachedOrFetch = storeCache("place")

const { Find, FindDailyReport } = Connection.getService("Place").mapStreams(["Find", "FindDailyReport"])
const { PutWorksheet } = Connection.getService("User").mapRequests(["PutWorksheet"])
const {
    Get,
    SetName,
    SetDescription,
    SetCity,
    SetLocation,
    SetOptionsPubliclySearchable,
    SetAutoActivatePromo,
    SetDailyReport,
    CopyDailyReport,
    AddPhotographer,
    RemovePhotographer,
    UpdatePhotographerShare,
    AddAdmin,
    RemoveAdmin,
    UpdateAdminShare,
    AddSeller,
    RemoveSeller,
    UpdateSellerShare,
    AddRetoucher,
    RemoveRetoucher,
    UpdateRetouchersShare,
    // eslint-disable-next-line no-unused-vars
    Create,
} = Connection.getService("Place").mapRequests([
    "Get",
    "SetName",
    "SetDescription",
    "SetCity",
    "SetLocation",
    "SetOptionsPubliclySearchable",
    "SetAutoActivatePromo",
    "SetDailyReport",
    "CopyDailyReport",
    "AddPhotographer",
    "RemovePhotographer",
    "UpdatePhotographerShare",
    "AddAdmin",
    "RemoveAdmin",
    "UpdateAdminShare",
    "AddSeller",
    "RemoveSeller",
    "UpdateSellerShare",
    "AddRetoucher",
    "RemoveRetoucher",
    "UpdateRetouchersShare",
    "Create",
])

const responseAdapter = (response, places) => {
    response.collection = response.collection.map((item, i) => ({ statistic: item, place: places[i] }))
        .filter(({ place }) => !!place)
    return response
}

export const namespaced = true

export const state = () => ({
    photographers: [],
    pageData: { place: null },
    invites: [],
})

export const getters = {
    getPageData(state) {
        return state.pageData
    },
    getPlacePhotographers(state) {
        return state.photographers
    },
    getInvites(state) {
        return state.invites
    },
}

export const mutations = {
    setPlacePhotographers(state, photographers) {
        state.photographers = photographers
    },
    setPageData(state, { place, dashboard }) {
        state.pageData.place = place
        state.pageData.dashboard = dashboard
    },
    setInvites(state, invites) {
        state.invites = invites
    },
}

export const actions = {
    create(ctx, attrs) {
        const payload = Object.assign({ LLID: "blr4mo1g20tc3o6gbcog" }, attrs)
        return Create(payload)
    },

    findByQuery(ctx, query) {
        return Find({ Name: query }).storeData()
    },

    fetchRawCollection(ctx, params = {}) {
        params.begin = params.begin || 0
        return GET_JSON("/places", params)
    },

    fetchCollection({ dispatch }, params = {}) {
        return dispatch("fetchRawCollection", params)
            .then(res => dispatch("inject", res))
    },

    fetchCollectionByPhotographer({ dispatch }, { photographerId, params }) {
        params.begin = params.begin || 0
        return GET_JSON(`/photographers/${encodeURIComponent(photographerId)}/places`, params)
            .then(res => dispatch("inject", res))
    },

    fetchById({ dispatch }, { id, filter }) {
        return dispatch("fetchCollection", Object.assign({}, filter, { ids: [id]}))
            .then(res => res.collection[0])
    },

    getById(ctx, id) {
        const fetch = () => Get({ ID: id })
        return cachedOrFetch(id, fetch).then(res => Object.assign({}, res))
    },

    updateName(ctx, { id, name }) {
        cachedOrFetch.purge(id)
        return SetName({ Name: name, PlaceID: id })
    },

    updatePubliclySearchable(ctx, { id, publiclySearchable }) {
        cachedOrFetch.purge(id)
        return SetOptionsPubliclySearchable({ PlaceID: id, PubliclySearchable: publiclySearchable })
    },

    getDashboardValue({ dispatch }, { id, event, from, placeKey = `key_Place` }) {
        const dates = [dayjs(), dayjs().subtract(1, "day")]

        const wheres = dates.map(date => [
            `name,=,${event}`,
            `${placeKey},=,${id}`,
            `date_add,>,${date.startOf("day").format("YYYY-MM-DD HH:mm:ss")}`,
            `date_add,<,${date.endOf("day").format("YYYY-MM-DD HH:mm:ss")}`,
        ].join("+"))

        return Promise.all(
            wheres.map(where => dispatch("execute/count", { from, where }, { root: true })),
        ).then(([today, yesterday]) => ({ today, yesterday }))
    },

    getPlaceDashboard({ dispatch }, id) {
        return Promise.all([
            dispatch("getDashboardValue", { id, event: "InfoWorksheetPut" }),
            dispatch("getDashboardValue", { id, event: "InfoUploadPhotoEvent" }),
            dispatch("getDashboardValue", { id, event: "InfoSalePhotoEvent" }),
            dispatch("getDashboardValue", { id, event: "view_photo", placeKey: "key_placeId", from: "web" }),
        ]).then(([worksheets, uploads, sales, viewPhotos]) => ({ worksheets, uploads, sales, viewPhotos }))
    },

    updateDescription(ctx, { id, description }) {
        cachedOrFetch.purge(id)
        return SetDescription({ Description: description, PlaceID: id })
    },

    updateCity(ctx, { id, city }) {
        cachedOrFetch.purge(id)
        return SetCity({ CityID: city, PlaceID: id })
    },

    updateLocation(ctx, { id, location }) {
        cachedOrFetch.purge(id)
        return SetLocation({ Location: location, PlaceID: id })
    },

    inject(ctx, statistic) {
        statistic.collection = statistic.collection || []
        statistic.count = statistic.count || []
        const placeIds = statistic.collection.map(i => i.placeId)
        return ctx.dispatch("getPlaces", placeIds)
            .then(places => responseAdapter(statistic, places))
    },

    getPlaces({ dispatch }, placeIds) {
        return idempotenceFetch(placeIds, id => dispatch("getById", id).catch(() => null))
    },

    fetchPlacePhotographers({ commit, dispatch }, photographerIds) {
        dispatch("photographer/getPhotographers", photographerIds, { root: true })
            .then(photographers => commit("setPlacePhotographers", photographers))
    },

    fetchOnGroundReports(ctx, params) {
        return GET_JSON("/onground", params)
    },

    fetchPage({ dispatch, commit }, id) {
        return Promise.all([
            dispatch("getPlaceDashboard", id).catch(() => null),
            dispatch("getById", id),
        ]).then(([dashboard, place]) => {
            commit("setPageData", { place, dashboard })
        })
    },

    injectData({ dispatch }, place) {
        return dispatch("photographer/getPhotographers", place.Photographers, { root: true })
            .then(Photographers => Object.assign(place, { Photographers }))
    },

    addAdmin(ctx, { ID, UserID, Share = 0 }) {
        cachedOrFetch.purge(ID)
        return AddAdmin({ ID, UserID, Share })
    },

    removeAdmin(ctx, { ID, UserID }) {
        cachedOrFetch.purge(ID)
        return RemoveAdmin({ ID, UserID })
    },

    updateAdminShare(ctx, { ID, UserID, Share }) {
        cachedOrFetch.purge(ID)
        return UpdateAdminShare({ ID, UserID, Share })
    },

    addPhotographer(ctx, { ID, UserID, Share = 0 }) {
        cachedOrFetch.purge(ID)
        return AddPhotographer({ ID, UserID, Share })
    },

    removePhotographer(ctx, { ID, UserID }) {
        cachedOrFetch.purge(ID)
        return RemovePhotographer({ ID, UserID })
    },

    updatePhotographerShare(ctx, { ID, UserID, Share }) {
        cachedOrFetch.purge(ID)
        return UpdatePhotographerShare({ ID, UserID, Share })
    },

    addRetoucher(ctx, { ID, UserID }) {
        cachedOrFetch.purge(ID)
        return AddRetoucher({ ID, UserID })
    },

    removeRetoucher(ctx, { ID, UserID }) {
        cachedOrFetch.purge(ID)
        return RemoveRetoucher({ ID, UserID })
    },

    updateRetouchersShare(ctx, { ID, Share }) {
        cachedOrFetch.purge(ID)
        return UpdateRetouchersShare({ ID, Share })
    },

    addSeller(ctx, { ID, UserID, Share = 0 }) {
        cachedOrFetch.purge(ID)
        return AddSeller({ ID, UserID, Share })
    },

    removeSeller(ctx, { ID, UserID }) {
        cachedOrFetch.purge(ID)
        return RemoveSeller({ ID, UserID })
    },

    updateSellerShare(ctx, { ID, UserID, Share }) {
        cachedOrFetch.purge(ID)
        return UpdateSellerShare({ ID, UserID, Share })
    },


    storeInvites({ dispatch, commit }, { placeId, status = "awaiting" }) {
        return Connection
            .getService("User")
            .stream("ListSendInvitations", { PlaceID: placeId, Status: status })
            .storeData()
            .then(res => {
                const ids = res.map(invite => invite.PhotographerID)
                return dispatch("user/getByIds", ids, { root: true }).then(users => {
                    return res.map((invite, i) => Object.assign(invite, { Photographer: users[i] }))
                })
            })
            .then(invites => {
                commit("setInvites", invites)
            })
    },

    cancelInvite(ctx, id) {
        return Connection
            .getService("User")
            .request("WithdrawInvitation", { InvitationID: id })
    },

    setAutoGeneratePromo(ctx, { placeId, value }) {
        return SetAutoActivatePromo({ PlaceID: placeId, AutoActivatePromo: value })
    },

    setDailyReport(ctx, attrs) {
        return SetDailyReport(attrs)
    },

    copyDailyReport(ctx, attrs) {
        return CopyDailyReport(attrs)
    },

    findDailyReports(ctx, { placeId, range }) {
        return FindDailyReport({
            PlaceID: placeId,
            Start: range.begin,
            End: range.end,
        }).storeData()
    },

    addWorksheet(ctx, attrs) {
        const lang = "RU"
        const payload = {
            Name: {
                First: { [lang]: attrs.firstName },
                Last: { [lang]: attrs.lastName },
            },
            Phone: attrs.phone,
            Email: attrs.email,
            PlaceID: attrs.placeId,
            PhotoTime: dayjs(attrs.photoTime).unix(),
            WorksheetTime: dayjs(attrs.worksheetTime).unix(),
        }

        return PutWorksheet(payload)
    },

    setLogo(ctx, { original, cropped, placeId }) {
        cachedOrFetch.purge(placeId)
        return POST_FORM(config.uploadPlaceLogo, FormData.fromJSON({
            Original: original,
            Cropped: cropped,
            PlaceID: placeId,
        }))
    },

    setWallpaper(ctx, { cropped, original, placeId }) {
        cachedOrFetch.purge(placeId)
        return POST_FORM(config.uploadPlaceWallpaper, FormData.fromJSON({
            Original: original,
            Cropped: cropped,
            PlaceID: placeId,
        }))
    },
}
