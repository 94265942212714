import Vue from "vue"
import VuetifyDialog from "vuetify-dialog"
import "vuetify-dialog/dist/vuetify-dialog.css"
import vuetify from "@/plugins/vuetify"
import DatepickerDialog from "../components/DatepickerDialog"
import UserAutocompleteDialog from "../components/UserAutocompleteDialog"
import { i18n } from "./i18n"

Vue.use(VuetifyDialog, {
    context: { vuetify },
    confirm: {
        actions: {
            false: {
                text: i18n.t(`actions['cancel']`),
                color: "blue-grey darken-1",
            },
            true: {
                text: i18n.t(`actions['continue']`),
                color: "deep-orange darken-4",
            },
        },
        icon: false,
        width: 290,
    },
    prompt: {
        actions: {
            false: {
                text: i18n.t(`actions['cancel']`),
                color: "blue-grey darken-1",
                key: false,
            },
            true: {
                text: i18n.t(`actions['continue']`),
                color: "deep-orange darken-4",
                key: null,
            },
        },
        icon: false,
        width: 320,
        showClose: false,
        persistent: true,
    },
    error: {
        icon: "mdi-alert-circle",
        actions: {
            false: { text: i18n.t(`actions['close']`) },
        },
    },
    loading: {
        dark: false,
        hideOverlay: false,
    },
})

// Implement datepicker dialog
Vue.prototype.$dialog.component("datepicker", DatepickerDialog, {
    showClose: false,
    persistent: true,
    waitForResult: true,
    asDate: true,
})

// Implement datepicker dialog
Vue.prototype.$dialog.component("selectUser", UserAutocompleteDialog, {
    persistent: true,
    waitForResult: true,
})

