<template>
    <v-col cols="12" sm="8" md="4" class="mx-auto">
        <v-card class="elevation-4">
            <v-toolbar color="blue-grey darken-5" dark flat>
                <v-toolbar-title>{{ $t(`headers['authorization']`) }}</v-toolbar-title>
                <v-spacer/>
            </v-toolbar>
            <v-card-text>
                <p class="text-center" v-show="visibleNext">{{ $t(`messages['login to continue']`) }}</p>
                <v-progress-linear
                    :active="loadingLogin"
                    :indeterminate="loadingLogin"
                    absolute
                    top
                    striped
                    color="orange"
                ></v-progress-linear>
                <login-form :errors="loginErrors"
                            :loading="loadingLogin"
                            v-model="loginData"
                            @submit="onSubmitLogin()"></login-form>
            </v-card-text>
        </v-card>
    </v-col>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { ResourceError } from "storemood-connection"
import LoginForm from "../components/LoginForm"

export default {
    name: "login",
    layout: "empty",
    components: { LoginForm },

    head() {
        return { title: this.$t("titles.login") }
    },

    fetch({ store, redirect }) {
        if (store.getters["common/isAuthorized"]) {
            return redirect("/")
        }
    },

    data() {
        return {
            visibleNext: "next" in this.$route.query,
            redirectTo: this.$route.query.next || "/",
            loginData: {},
            loginErrors: {},
            loadingLogin: false,
        }
    },

    computed: {
        ...mapGetters("common", ["getUser"]),

        isPermitted() {
            const { IsPhotographer, JS, AdminingPlaces, SellerPlaces, RetoucherPlaces, LLPlaces } = this.getUser
            return IsPhotographer || JS || AdminingPlaces.length !== 0 || SellerPlaces.length !== 0 || RetoucherPlaces.length !== 0 || LLPlaces.length !== 0
        },
    },

    methods: {
        ...mapActions("common", ["signIn", "logout"]),
        onSubmitLogin() {
            this.loadingLogin = true
            this.loginErrors = {}
            this.signIn(this.loginData)
                .then(this.onSignInSuccess.bind(this))
                .catch(this.onSignInError.bind(this))
        },

        onSignInSuccess() {
            if (this.isPermitted) {
                this.$router.replace(this.redirectTo)
                    .catch(() => this.$router.replace("/"))
            } else {
                this.logout()
                this.$router.replace("/forbidden")
            }
        },

        onSignInError(e) {
            this.loadingLogin = false
            const errors = {}
            new ResourceError(e)
                .ifCode("verification code mismatch", () => errors.password = this.$t(`errors['wrong password']`))
                .ifCode("email is not valid", () => errors.identity = this.$t(`errors['wrong login']`))
                .ifCode("user not found", () => errors.identity = this.$t(`errors['login not found']`))
            this.loginErrors = errors
        },
    },
}
</script>
