<template>
    <div class="dynamic-chart">
        <div :id="rootId"></div>
    </div>
</template>

<script>
import Highcharts from "highcharts"
import BaseChart from "./BaseChart"
import deepAssign from "../../util/deep-assign"
import { i18n } from "../../plugins/i18n"

const transformSeries = (series, theme) => {
    if (theme !== "md") {
        return series
    }

    return series.map(serie => {
        // serie.showInLegend = false
        serie.type = "areaspline"
        serie.color = serie.color || "rgba(255, 255, 255, .8)"
        return serie
    })
}

const getStackedChartOptions = () => ({
    chart: {
        type: "area",
    },
    legend: {
        layout: "horizontal",
        align: "center",
        verticalAlign: "bottom",
        itemStyle: { color: "#000" },
    },
    yAxis: {
        title: {
            text: i18n.t('headers.sales'),
        },
    },
    plotOptions: {
        area: {
            stacking: "normal",
            lineColor: "#666666",
            lineWidth: 1,
            marker: {
                lineWidth: 1,
                lineColor: "#666666",
            },
        },
    },
})

const getMdChartOptions = () => {
    return {
        chart: {
            backgroundColor: "transparent",
            height: "156px",
            margin: 4,
        },

        legend: {
            layout: "horizontal",
            align: "center",
            verticalAlign: "top",
            itemStyle: { color: "#fff" },
        },

        plotOptions: {
            series: {
                label: {
                    color: "#fff",
                    connectorAllowed: false,
                },
            },
            areaspline: {
                fillOpacity: 0,
            },
        },
        yAxis: {
            labels: { enabled: true },
            gridLineWidth: 1,
            gridLineColor: "rgba(255, 255, 255, .5)",
            gridLineDashStyle: "shortdash",
            lineWidth: 0,
        },
        xAxis: {
            gridLineWidth: 1,
            gridLineColor: "rgba(255, 255, 255, .5)",
            gridLineDashStyle: "shortdash",
            lineWidth: 0,
        },
    }
}

const getDefaultChartOptions = () => ({
    credits: {
        enabled: false,
    },
    title: { text: "" },
    legend: {
        layout: "vertical",
        align: "right",
        verticalAlign: "middle",
    },
    // plotOptions: {
    //   series: {
    //     showInLegend: false,
    //     label: { connectorAllowed: false },
    //   },
    // },
    // tooltip: {
    //   formatter: function() {
    //     return `
    //         <b>${this.point.label}</b><br>
    //         ${this.series.name}:<b>${this.point.tooltipText || this.point.y}</b>
    //     `
    //   },
    // },
})

const getChartOptions = (theme, background) => {
    const options = getDefaultChartOptions()
    if (theme === "md") {
        return deepAssign(options, getMdChartOptions(background))
    }

    if (theme === "area") {
        return deepAssign(options, getStackedChartOptions())
    }
    return options
}

export default {
    mixins: [BaseChart],
    props: {
        background: {
            type: String,
        },
    },
    methods: {
        init() {
            const opts = deepAssign(getChartOptions(this.theme, this.background), this.options)
            return Highcharts.chart(this.rootId, opts)
        },

        onUpdateValue() {
            this.updateSeries(transformSeries(this.value, this.theme))
        },
    }
}
</script>

<style>
    .dynamic-chart {
        width: 100%;
        padding: 16px 16px;
        box-sizing: border-box;
    }
</style>
