<template>
    <div>
        <autocomplete v-model="model"
                      :label="$t(`labels['choose photographer']`)"
                      :items="photographers"
                      :multiple="multiple"
                      :loading="isLoading"
                      :to-option="photographerToOption"
                      @change="onChangeQuery"/>
    </div>
</template>
<script>
import { mapActions } from "vuex"
import * as Format from "@/util/format"
import unobserve from "@/util/unobserve"
import Autocomplete from "@/components/Autocomplete"

export default {
    components: { Autocomplete },
    props: {
        value: [Array, Object],
        multiple: Boolean,
        params: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            model: !this.value ? this.value : unobserve(this.value),
            isLoading: false,
            photographers: [],
            requestId: 0,
        }
    },
    watch: {
        model() {
            this.$emit("input", this.model)
        },
        value() {
            this.model = this.value
        },
    },
    methods: {
        ...mapActions("photographer", ["findByQuery"]),
        photographerToOption(photographer) {
            return {
                id: photographer.ID,
                name: `${Format.getUserName(photographer.Name)}`,
            }
        },

        onChangeQuery(q) {
            this.isLoading = true
            this.requestId++
            const { requestId } = this
            this.findByQuery({ query: q, params: this.params }).then(photographers => {
                if (this.requestId === requestId) {
                    this.isLoading = false
                    this.photographers = photographers
                }
            })
        },
    },
}
</script>
