import Connection from "@/plugins/connection"
import config from "config"
import dayjs from "dayjs"
import { GET_JSON, POST_FORM } from "../util/resource"
import idempotenceFetch from "../util/idempotence-fetch"
import storeCache from "../util/store-cache"
import FormData from "../util/form-data"

const cachedOrFetch = storeCache("user")

const {
    GetOwnedPlaces,
    GetAdminPlaces,
    FindPhotographers,
    GetEmployedPlaces,
    ListMyInvitations,
    Find,
} = Connection.getService("User").mapStreams([
    "GetOwnedPlaces",
    "GetAdminPlaces",
    "FindPhotographers",
    "GetEmployedPlaces",
    "ListMyInvitations",
    "Find",
])
const {
    GetByID,
    AcceptInvitation,
    RejectInvitation,
    SetName,
} = Connection.getService("User").mapRequests([
    "GetByID",
    "AcceptInvitation",
    "RejectInvitation",
    "SetName",
])

const {
    StartAttach,
    Start,
    Verify,
} = Connection.getService("Identity").mapRequests([
    "StartAttach",
    "Start",
    "Verify",
])

export const namespaced = true

export const state = () => ({
    adminPlaces: [],
    ownedPlaces: [],
    photographers: [],
    dashboard: {
        weekly: [],
        daily: {},
    },
    myInvites: [],
    myEmployedPlaces: [],
})

export const getters = {
    adminPlaces(state) {
        return state.adminPlaces
    },
    ownedPlaces(state) {
        return state.ownedPlaces
    },
    getPhotographers(state) {
        return state.photographers
    },
    getDashboard(state) {
        return state.dashboard
    },
    getMyInvites(state) {
        return state.myInvites
    },
    getMyEmployedPlaces(state) {
        return state.myEmployedPlaces
    },
}

export const mutations = {
    adminPlaces(state, places) {
        state.adminPlaces = places
    },
    ownedPlaces(state, places) {
        state.ownedPlaces = places
    },
    setPhotographers(state, photographers) {
        state.photographers = photographers
    },
    setDashboard(state, dashboard) {
        state.dashboard = dashboard
    },
    setMyInvites(state, invites) {
        state.myInvites = invites
    },
    setMyEmployedPlaces(state, places) {
        state.myEmployedPlaces = places
    },
}

export const actions = {
    fetchDashboard({ dispatch, rootGetters, commit }, range = { begin: null, end: null }) {
        const now = dayjs()
        const userId = rootGetters["common/getUserId"]
        const data = { begin: range.begin || now.subtract(1, "month").startOf("day"), end: range.end || now.endOf("day") }

        return dispatch("getUserStatistics", { id: userId, params: data })
            .then(r => r.collection)
            .then(result => {
                commit("setDashboard", {
                    daily: result,
                    chart: result,
                    dailyRange: data,
                    weeklyRange: data,
                    useDefaults: false,
                })
            })
    },

    getUserStatistics(ctx, { id, params }) {
        return GET_JSON(`/users/${encodeURIComponent(id)}`, params)
            .then(res => ({ collection: res.collection || [], count: res.count || 0 }))
    },

    getMyPlaces({ commit }) {
        Promise.all([
            GetAdminPlaces().storeData(),
            GetOwnedPlaces().storeData(),
        ]).then(([admin, owned]) => {
            commit("adminPlaces", admin)
            commit("ownedPlaces", owned)
        })
    },

    storeMyInvitations({ commit, dispatch }, status = "awaiting") {
        return ListMyInvitations({ Status: status })
            .storeData()
            .then(res => {
                const ids = res.map(invite => invite.PlaceID)
                return dispatch("place/getPlaces", ids, { root: true }).then(places => {
                    return res.map((invite, i) => Object.assign(invite, { Place: places[i] }))
                })
            })
            .then(invites => {
                commit("setMyInvites", invites)
            })
    },

    acceptInvitation(ctx, id) {
        return AcceptInvitation({ InvitationID: id })
    },

    declineInvitation(ctx, id) {
        return RejectInvitation({ InvitationID: id })
    },

    getUploadPlaces({ rootGetters }) {
        return Promise.all([
            GetAdminPlaces().storeData(),
            GetEmployedPlaces({ ID: rootGetters["common/getUserId"] }).storeData(),
        ]).then(([admin, employed]) => admin.concat(employed))
            .then(places => {
                const ids = places.map(p => p.ID)
                return places.filter((p, i) => ids.indexOf(p.ID) === i)
            })
    },

    getPlacePhotographers({ commit }, { query = null, placeId, excludePlaceId = null, limit = Number.MAX_SAFE_INTEGER, offset = null }) {
        const payload = {
            Query: query,
            PlaceID: placeId,
            ExcludePlaceID: excludePlaceId,
            limit: limit,
            offset: offset,
        }
        return FindPhotographers(payload)
            .storeData()
            .then(photographers => {
                commit("setPhotographers", photographers)
            })
    },

    findPhotographers(ctx, { query = null, placeId }) {
        const payload = {
            Query: query,
            PlaceID: null,
            ExcludePlaceID: placeId,
            ExcludeEmpty: true,
            limit: Number.MAX_SAFE_INTEGER,
            offset: null,
        }
        return FindPhotographers(payload)
            .storeData()
    },

    findByQuery(ctx, { query = null, params = {} }) {
        return Find(Object.assign({ Query: query, Limit: 20 }, params))
            .storeData()
    },

    getAdminPlaces({ commit }) {
        return GetAdminPlaces()
            .storeData()
            .then(places => {
                commit("adminPlaces", places)
            })
    },

    getEmployedPlaces(ctx, id) {
        return GetEmployedPlaces({ ID: id }).storeData()
    },

    storeMyEmployedPlaces({ commit, dispatch, rootGetters }) {
        const userId = rootGetters["common/getUserId"]
        return dispatch("getEmployedPlaces", userId)
            .then(places => {
                // eslint-disable-next-line no-console
                console.log('getEmployedPlaces = ', places)
                commit("setMyEmployedPlaces", places)
            })
    },

    getById(ctx, id) {
        const fetch = () => GetByID({ ID: id })
        return cachedOrFetch(id, fetch).then(res => Object.assign({}, res))
    },

    getByIds({ dispatch }, ids) {
        return idempotenceFetch(ids, id => dispatch("getById", id))
    },

    setAvatar(ctx, { cropped, original }) {
        return POST_FORM(config.uploadAvatar, FormData.fromJSON({
            Original: original,
            Cropped: cropped,
        }))
    },

    setWallpaper(ctx, { cropped, original }) {
        return POST_FORM(config.uploadUserWallpaper, FormData.fromJSON({
            Original: original,
            Cropped: cropped,
        }))
    },

    setName(ctx, attrs) {
        return SetName({ Name: attrs })
    },

    attachPassword(ctx, password) {
        return StartAttach()
            .then(() => Start({ VerifierName: "password", Args: { password: password } }))
            .then(() => Verify({ VerifierName: "password", VerificationCode: password }))
    },
}
