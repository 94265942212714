<template>
    <v-app id="inspire">
        <div class="layout__progress" v-show="isLoadingPage">
            <v-progress-linear indeterminate color="white"></v-progress-linear>
        </div>

        <v-content>
            <v-container class="fill-height" fluid>
                <v-row align="center" class="mx-auto" justify="center">
                    <slot></slot>

                </v-row>
            </v-container>
        </v-content>
    </v-app>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters('common', ['isLoadingPage']),
    }
}
</script>
