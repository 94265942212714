import Connection from "@/plugins/connection"

const { GetCountries, GetCities, FindCity, GetAllProducts } = Connection.getService("Directory").mapStreams(["GetCountries", "GetCities", "FindCity", "GetAllProducts"])
const { CreateCountry, CreateCity, UpdateCountry, UpdateCity, GetCountry, DeleteCountry, DeleteCity } = Connection.getService("Directory")
    .mapRequests([
        "CreateCountry",
        "CreateCity",
        "UpdateCountry",
        "UpdateCity",
        "GetCountry",
        "GetCity",
        "DeleteCountry",
        "DeleteCity",
    ])

export const namespaced = true

export const state = () => ({
    countriesList: [],
    citiesList: [],
    city: null,
    country: null,
    products: {
        list: [],
        fetched: false,
    },
})

export const getters = {
    getCountries(state) {
        return state.countriesList
    },
    getCities(state) {
        return state.citiesList
    },
    getCitiesByCountry: state => countryId => {
        return state.citiesList.filter(city => city.CountryID === countryId)
    },
    getCityById: state => cityId => {
        return state.citiesList.find(city => city.ID === cityId)
    },
    getCountryById: state => countryId => {
        return state.countriesList.find(country => country.ID === countryId)
    },
    getCountry(state) {
        return state.country
    },
    getCity(state) {
        return state.city
    },
    getProducts(state) {
        return state.products.list
    },
}

export const mutations = {
    setCountries(state, countries) {
        state.countriesList = countries
    },
    setCountry(state, country) {
        state.country = country
    },
    setCities(state, cities) {
        state.citiesList = cities
    },
    setCity(state, city) {
        state.city = city
    },
    setProducts(state, products) {
        state.products.fetched = true
        state.products.list = products
    },
}

export const actions = {
    getCountries() {
        return GetCountries({}).storeData()
    },

    // eslint-disable-next-line no-unused-vars
    findCountryByQuery(ctx, { query, params }) {
        return GetCountries({}).storeData()
    },

    findCity(ctx, query) {
    // eslint-disable-next-line no-console
        return FindCity({ Name: query }).storeData()
    },

    getCities({ dispatch }, params = {}) {
    // eslint-disable-next-line no-console

        return GetCities(params)
            .storeData()
            .then(list => dispatch("injectCountry", list))
    },

    createCountry(ctx, payload) {
        return CreateCountry(payload)
    },

    createCity(ctx, payload) {
        return CreateCity(payload)
    },

    updateCountry(ctx, attrs) {
        return UpdateCountry(attrs)
    },

    updateCity(ctx, attrs) {
        return UpdateCity(attrs)
    },

    deleteCountry(ctx, id) {
        return DeleteCountry({ ID: id })
    },

    deleteCity(ctx, id) {
        return DeleteCity({ ID: id })
    },

    injectCountry(ctx, cities) {
        return Promise.all(
            cities.map(city => GetCountry({ ID: city.CountryID })),
        ).then(res => cities.map((city, i) => Object.assign(city, { Country: res[i] })))
    },


    // Products
    fetchProducts({ state, getters, commit }) {
        if (state.products.fetched) {
            return Promise.resolve(getters.getProducts)
        }

        return GetAllProducts()
            .storeData()
            .then(products => {
                commit("setProducts", products)
                return products
            })
    },
}
