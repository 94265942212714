import onLoadWindow from "storemoodify/util/onload"

const COUNTER_ID = 58801717

let initializePromise = null

const load = () => {
    let initialized = false

    if (initializePromise !== null) {
        return initializePromise
    }

    window.ym = window.ym || function() {
        // (window.ym.a = window.ym.a || []).push(arguments)
    }
    window.ym.l = 1 * new Date()

    const onLoad = () => {
        if (initialized) {
            return
        }
        initialized = true

        window.ym(COUNTER_ID, "init", {
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: true,
            trackHash: true,
        })
    }

    return initializePromise = onLoadWindow()
        .then(() => import("resource-loader"))
        .then(({ loadScript }) => loadScript("https://cdn.jsdelivr.net/npm/yandex-metrica-watch/tag.js"))
        .then(onLoad)
}

export default class YaMetrika {
    static init() {
        return load()
    }

    static reachGoal(id, attrs) {
        load().then(() => window.ym(COUNTER_ID, "reachGoal", id, attrs))
    }

    static hit() {
        load().then(() => window.ym(COUNTER_ID, "hit", window.location.href))
    }

    static setParams(params) {
        load().then(() => window.ym(COUNTER_ID, "userParams", params))
    }
}
