<template>
    <div>
        <h3 class="title">{{ $t(`headers['conversion sources']`) }}</h3>
        <div class="d-flex align-center justify-center flex-column py-10 my-10" v-if="loading">
            <v-progress-circular
                :size="50"
                color="blue-grey darken-4"
                indeterminate/>
            <p class="subtitle-1 mt-4">{{ $t(`messages['loading data']`) }}</p>
        </div>

        <div v-if="!loading">
            <h3 class="subtitle-1 font-italic">
                {{ $t(`headers['conversion sheets']`) }} <b>{{getTotalVisits.worksheets}}</b>
            </h3>

            <h3 class="subtitle-1 font-italic">
                {{ $t(`headers['conversion codes']`) }} <b>{{getTotalVisits.promo}}</b>
            </h3>

            <dynamic-chart :options="getOptions" theme="area" v-model="getSeries"/>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex"
import dayjs from "dayjs"
import DynamicChart from "../charts/DynamicChart"
import { i18n } from "../../plugins/i18n"

const getOptions = () => {
    return {
        yAxis: {
            title: {
                text: i18n.t('rudiments.visits'),
            },
        },
        tooltip: {
            pointFormat: "<span style=\"color:{series.color}\">{series.name}</span>: <b>{point.percentage:.1f}%</b> ({point.y:,.0f})<br/>",
            split: true,
            formatter: function() {
                const s = []
                s.push(dayjs(new Date(this.x)).format("DD.MM"))
                this.points.forEach((point) => {
                    s.push(`<b>${point.series.name}: ${point.y}</b>(${Math.round(point.percentage * 100) / 100}%)`)
                })
                return s
            },
        },
        plotOptions: {
            area: {
                lineColor: "#ffffff",
                lineWidth: 1,
                marker: {
                    lineWidth: 1,
                    lineColor: "#ffffff",
                },
                accessibility: {
                    pointDescriptionFormatter: function(point) {
                        function round(x) {
                            return Math.round(x * 100) / 100
                        }

                        return (point.index + 1) + ", " + point.category + ", " +
                            point.y + ", " + round(point.percentage) + "%, " +
                            point.series.name
                    },
                },
            },
            series: {
                stacking: "normal",
            },
        },
        xAxis: {
            type: "datetime",
            tickmarkPlacement: "on",
            title: {
                enabled: false,
            },
            labels: {
                formatter() {
                    return dayjs(new Date(this.value)).format("DD.MM")
                },
            },
        },
    }
}

export default {
    components: { DynamicChart },
    props: {
        dateRange: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            loading: false,
            minHeight: 0,
            data: [],
        }
    },
    computed: {
        getSeries() {
            const { data, dateRange } = this
            const series = data.reduce((res, day) => {
                const date = dayjs(day.d).format("DD.MM")
                res.promo.push({ date: date, label: date, y: day.VisitsByQR })
                res.worksheets.push({ date: date, label: date, y: day.VisitsByWorksheet })
                return res
            }, { promo: [], worksheets: []})

            const seriesOptions = { pointStart: +dateRange.begin, pointInterval: 24 * 3600 * 1000 }

            return [
                { name: this.$t(`rudiments['conversion sheets']`), data: series.worksheets, color: "#f9433f", ...seriesOptions },
                { name: this.$t(`rudiments['conversion codes']`), data: series.promo, color: "#f38b47", ...seriesOptions },
            ]
        },

        getTotalVisits() {
            const { data } = this
            return data.reduce((res, day) => {
                res.promo += day.VisitsByQR
                res.worksheets += day.VisitsByWorksheet
                return res
            }, { promo: 0, worksheets: 0 })
        },

        getOptions() {
            return getOptions()
        },
    },
    watch: {
        dateRange: {
            immediate: true,
            deep: true,
            handler() {
                this.fetchData()
            },
        },
    },
    methods: {
        ...mapActions("statistic", ["getVisitsSource"]),
        fetchData() {
            this.loading = true
            this.getVisitsSource(this.dateRange).then(res => {
                this.data = res
            }).finally(() => {
                this.loading = false
                this.$nextTick(this.onLoadData)
            })
        }
    }
}
</script>
