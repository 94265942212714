<template>
    <v-card class="elevation-1" outlined>
        <v-card-title>
            {{ $t(`errors['data error']`) }}
        </v-card-title>
        <v-card-text>
            {{ $t(`errors['try again error']`) }}
        </v-card-text>
        <v-card-actions mx-auto>
            <v-btn text color="primary" @click="onAgain()">
                {{ $t('actions.retry') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: "TableError",
    methods: {
        onAgain() {
            this.$emit("again")
        },
    },
}
</script>
