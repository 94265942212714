<template>
    <div>
        <div v-if="loading" class="d-flex justify-center mt-8 mb-8">
            <v-progress-circular
                :size="50"
                color="blue-grey darken-4"
                indeterminate/>
        </div>

        <div v-if="!loading && !loadingError">
            <h4 class="headline">{{$t("headers.top", [previousMonth])}}</h4>
            <div class="top-list">
                <div class="top-list__row top-list__row_head">
                    <div class="top-list__ceil_number body-1 font-weight-bold">#</div>
                    <div class="top-list__ceil"><b>{{ $t(`labels['top places']`) }}</b></div>
                    <div class="top-list__ceil"><b>{{ $t(`labels['top photographers']`) }}</b></div>
                    <div class="top-list__ceil"><b>{{ $t(`labels['top admins']`) }}</b></div>
                </div>

                <virtual-list :remain="10">
                    <div class="top-list__row" :v-height="64" v-for="(_, index) in getRowsArray" :key="index">
                        <div class="top-list__ceil_number body-1 font-weight-bold">{{ index + 1 }}.</div>
                        <div class="top-list__ceil">
                            <div v-if="getTop.places[index]">
                                <v-list-item class="ml-0 pl-0" :key="getTop.places[index].place.ID">
                                    <v-list-item-avatar size="30" tile>
                                        <v-img :src="getTop.places[index].place.Avatar.Cropped | getMedia('place')"/>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-wrap">
                                            {{ getTop.places[index].place.Name | getName }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            {{ $t(`rudiments['sold for']`) }}
                                            {{ getTop.places[index].statistic.PhotosStatistic.salesAmountCash +
                                                getTop.places[index].statistic.PhotosStatistic.salesAmountPromo | currency}}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </div>
                            <div v-else class="text-center">-</div>
                        </div>
                        <div class="top-list__ceil">
                            <div v-if="getTop.photographers[index]">
                                <v-list-item class="ml-0 pl-0" :key="getTop.photographers[index].photographer.ID">
                                    <v-list-item-avatar size="30">
                                        <v-img :src="getTop.photographers[index].photographer.Avatar.Cropped | getMedia"/>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-wrap">
                                            {{ getTop.photographers[index].photographer.Name | getName }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            {{ $t(`rudiments['photos made']`,
                                            [getTop.photographers[index].statistic.PhotosStatistic.made]) }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </div>
                            <div v-else class="text-center">-</div>
                        </div>
                        <div class="top-list__ceil">
                            <div v-if="getTop.admins[index]">
                                <v-list-item class="ml-0 pl-0" :key="getTop.admins[index].admin.ID">
                                    <v-list-item-avatar size="30">
                                        <v-img :src="getTop.admins[index].admin.Avatar.Cropped | getMedia"/>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-wrap">
                                            {{ getTop.admins[index].admin.Name || {} | getName }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            {{ $t(`rudiments['sheets created']`,
                                            [getTop.admins[index].statistic.worksheetCreated]) }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </div>
                            <div v-else class="text-center">-</div>
                        </div>
                    </div>
                </virtual-list>
            </div>
        </div>
        <table-error @again="loadTop()" v-show="loadingError && !loading"/>
    </div>
</template>
<script>
import dayjs from "dayjs"
import { mapGetters, mapActions } from "vuex"
import VirtualList from "storemoodify/lib/VirtualList"
import TableError from "./TableError"

export default {
    components: { TableError, VirtualList },
    data() {
        return {
            loading: false,
            loadingError: false,
        }
    },
    mounted() {
        this.loadTop()
    },
    methods: {
        ...mapActions("top", ["storeTop"]),
        loadTop() {
            this.loading = true
            this.loadingError = false
            return this.storeTop()
                .then(() => {
                    this.loading = false
                    this.loadingError = false
                })
                .catch(() => {
                    this.loadingError = true
                })
                .finally(() => {
                    this.loading = false
                })
        },
    },
    computed: {
        ...mapGetters("top", ["getTop"]),
        getRowsArray() {
            const { admins, photographers, places } = this.getTop
            const count = Math.max(admins.length, places.length, photographers.length)
            return new Array(count).fill(null)
        },
        previousMonth() {
            return dayjs().subtract(1, "month").format("MMMM")
        },
    },
}
</script>
<style lang="stylus">
    .top-list {
        margin-top 24px
    }

    .top-list__row {
        display flex
        align-items: center;
        height 64px
        border-bottom: 1px solid rgba(0, 0, 0, .12);
    }

    .top-list__row:not(.top-list__row_head):hover {
        background: #eee;
    }

    .top-list__row_head {
        height 48px
    }

    .top-list__ceil {
        flex 1
        padding: 0 16px;
    }

    .top-list__ceil_number {
        flex: none;
        width: 60px;
        padding: 0 16px;
    }

    .top-list__row_head {
        font-size: 12px;
        color: rgba(0, 0, 0, .54);
    }
</style>
