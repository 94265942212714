<template>
    <div>
        <v-container fluid style="background: #fff">
            <p class="headline mb-6"> {{ $t(`headers['ranged places stats']`, [getRangeLabel]) }}</p>

            <place-filter :allow-places="isJesus ? null :getUser.AdminingPlaces" @submit="onSubmitFilter()" v-model="filter"/>

            <place-table class="mt-6"
                         v-show="!queryFailed"
                         v-model="statistics"
                         :loading="loading"
                         :promo="getContext"
                         :options.sync="tableOptions"
                         :extended="isJesus"
            />
            <table-error v-show="queryFailed" @again="onSubmitFilter()"/>
        </v-container>
    </div>
</template>

<script>
import dayjs from "dayjs"
import { mapActions, mapGetters } from "vuex"
import PlaceFilter from "./Filter"
import PlaceTable from "./Table"
import TableError from "../TableError"
import * as Format from "../../util/format"
import unobserve from "../../util/unobserve"

const defaultOptions = () => ({
    "limit": 500,
    "offset": 0,
    "orderBy": "PhotosStatistic.sales desc",
})

const defaultFilter = () => {
    const now = dayjs()
    return {
        places: [],
        begin: now.startOf("day").toDate(),
        end: now.endOf("day").toDate(),
    }
}

export default {
    components: { PlaceFilter, PlaceTable, TableError },
    props: {
        params: {
            type: Object,
            default: () => ({}),
        },
        context: {
            type: String,
            default: "default",
        },
    },
    data() {
        return {
            loading: false,
            queryFailed: false,
            filter: defaultFilter(),
            submittedFilter: defaultFilter(),
            tableOptions: defaultOptions(),
            statistics: { collection: [], count: 0 },
        }
    },

    computed: {
        ...mapGetters("common", ["isJesus", "isPlaceAdmin", "getUser"]),
        getRangeLabel() {
            const { begin, end } = this.submittedFilter
            return Format.dateRange(begin, end)
        },
        getContext() {
            return this.context === "default"
        },
    },

    watch: {
        tableOptions() {
            this.fetch()
        },
        submittedFilter() {
            this.fetch()
        },
    },

    created() {
        this.$request = 0
        this.fetch()
    },

    methods: {
        ...mapActions("place", ["fetchCollection", "fetchCollectionByPhotographer"]),

        fetch() {
            this.queryFailed = false
            this.loading = true
            const request = ++this.$request

            this.getFetchRequest()
                .then(result => {
                    if (request === this.$request) {
                        this.queryFailed = false
                        this.statistics = result
                    }
                })
                .catch(() => {
                    if (request === this.$request) {
                        this.queryFailed = true
                    }
                })
                .finally(() => {
                    if (request === this.$request) {
                        this.loading = false
                    }
                })
        },

        getFetchRequest() {
            if ("photographerId" in this.params) {
                return this.fetchCollectionByPhotographer({
                    photographerId: this.params.photographerId,
                    params: this.getRequest(),
                })
            }
            return this.fetchCollection(this.getRequest())
        },

        getRequest() {
            const options = Object.assign({}, this.tableOptions, this.submittedFilter)

            if (!this.isJesus) {
                const adminingPlaces = this.getUser.AdminingPlaces
                const ids = options.ids || []

                if (ids.length === 0) {
                    options.ids = adminingPlaces
                } else {
                    options.ids = ids.filter((id) => adminingPlaces.includes(id))
                }
            }

            return options
        },

        onSubmitFilter() {
            const ids = this.filter.places.map(p => p.ID)
            this.submittedFilter = unobserve({ ...this.filter, places: null, ids })
        },
    },
}
</script>
