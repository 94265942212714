import Cache from "../util/Cache"

const cache = new Cache()

export default (namespace) => {
    function cacheOrFetch(key, fetch, ttl) {
        const cachedKey = `${namespace}-${key}`
        const cached = cache.get(cachedKey)

        if (cached !== null) {
            return Promise.resolve(cached)
        }

        return fetch(key).then(result => {
            cache.put(cachedKey, result, ttl)
            return result
        })
    }

    cacheOrFetch.purge = key => cache.deleteByKey(`${namespace}-${key}`)
    return cacheOrFetch
}
