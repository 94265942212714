<template>
    <v-navigation-drawer
        id="app-drawer"
        v-model="opened"
        :permanent="$vuetify.breakpoint.lgAndUp"
        app
        dark
        floating
        persistent
        mobile-breakpoint="991"
        width="260"
        theme="dark">
        <v-img src="/images/collage.jpg" height="100%">

            <div class="drawer-list fill-height">
                <v-list nav>
                    <v-list-group value="true" active-class="app-drawer__user">
                        <template v-slot:activator>
                            <v-list-item-avatar class="mr-2">
                                <v-img :src="getUserAvatar"/>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title v-text="getUserName" class="text-wrap"></v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <v-list-item style="height: 48px" to="/profile" active-class="app-drawer__user">
                            <span class="mr-2"><v-icon>mdi-account</v-icon></span>
                            <v-list-item-title>{{ $t(`menu['profile']`) }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="onClickLogout()" style="height: 48px" active-class="app-drawer__user">
                            <span class="mr-2"><v-icon>mdi-logout</v-icon></span>
                            <v-list-item-title>{{ $t(`actions['logout']`) }}</v-list-item-title>
                        </v-list-item>
                    </v-list-group>
                    <v-divider class="mt-2 mb-2"/>
                    <v-list-item style="height: 48px" to="/tickets" active-class="app-drawer__user" v-if="canVisibleTickets">
                        <span class="mr-2"><v-icon>mdi-face-agent</v-icon></span>
                        <v-list-item-title>
                            Обращения
                            <v-badge style="margin-left: 8px" color="red" v-if="getOpenedTicketsCount"
                                     :content="getOpenedTicketsCount"/>
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item style="height: 48px"
                                 v-for="(item, $i) in getNavItems"
                                 :disabled="item.disabled"
                                 :key="$i"
                                 :exact="item.exact"
                                 link
                                 :to="item.to"
                                 :href="item.href"
                    >
                        <span class="mr-2">
                            <v-icon>{{ item.icon }}</v-icon>
                        </span>
                        <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </div>
        </v-img>
    </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import * as Format from "@/util/format"

export default {
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            opened: this.value,
        }
    },

    computed: {
        ...mapGetters("common", ["getUser", "isPlaceAdmin", "isPhotographer", "isJesus"]),
        ...mapGetters("support", ["getTickets", "getOpenedTicketsCount"]),

        getUserName() {
            const { First, Last } = (this.getUser || {}).Name || {}
            return `${Format.getName(First)} ${Format.getName(Last)}`
        },

        getUserAvatar() {
            const { Cropped } = (this.getUser || {}).Avatar || {}
            return Format.getMedia(Cropped)
        },

        canVisibleTickets() {
            const { getTickets } = this

            return getTickets.length && (this.isPlaceAdmin || this.isJesus)
        },

        getNavItems() {
            const items = [
                {
                    title: this.$t(`menu['dashboard']`),
                    to: "/",
                    icon: "mdi-view-dashboard",
                },
                {
                    title: this.$t("toolbar.places"),
                    to: "/places",
                    icon: "mdi-map-marker",
                    exact: true,
                },
            ]

            if (this.isJesus) {
                items.push({
                    title: this.$t(`menu['create place']`),
                    icon: "mdi-map-marker-plus-outline",
                    to: "/places/create",
                })
            }

            items.push({
                title: this.$t(`menu['statistics']`),
                icon: "mdi-chart-bell-curve",
                href: "https://statistics.storemood.com/statistics",
            })

            if (this.isJesus) {
                items.push({
                    title: this.$t(`menu['directory']`),
                    icon: "mdi-format-list-bulleted",
                    to: "/directory",
                })
            }

            if (this.isPlaceAdmin || this.isPhotographer) {
                items.push({
                    title: this.$t(`menu['upload']`),
                    icon: "mdi-cloud-upload-outline",
                    to: "/upload",
                })
            }

            if (this.isPlaceAdmin) {
                items.push({
                    title: this.$t('menu.daily reports'),
                    icon: 'mdi-timetable',
                    href: "https://statistics.storemood.com/daily-reports",
                })
            }

            items.push(
                {
                    title: this.$t(`menu['training']`),
                    icon: "mdi-account-tie",
                    to: "/training",
                    disabled: true
                },
                {
                    title: this.$t(`menu['apps']`),
                    icon: "mdi-apple-icloud",
                    href: "https://statistics.storemood.com/apps",
                }
            )

            return items
        },
    },

    watch: {
        value(val) {
            this.opened = val
        },
        opened(val) {
            this.$emit("input", val)
        },
    },
    methods: {
        ...mapActions("common", ["logout"]),
        onClickLogout() {
            this.$router.replace("/login")
            this.logout()
        },
    },
}
</script>
<style lang="stylus">
.v-navigation-drawer .drawer-list {
  padding: 0;
  border-radius: 0;
  overflow: auto;
  background: rgba(27, 27, 27, 0.74) !important;

  .v-list {
    background: transparent;
  }

  .app-drawer__user {
    color #ffffff
  }

  .app-drawer__count {

  }
}
</style>
