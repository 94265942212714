const cookie = {
    get(key) {
        const matches = document.cookie.match(new RegExp(
            // eslint-disable-next-line no-useless-escape
            "(?:^|; )" + key.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + "=([^;]*)",
        ))
        return matches ? decodeURIComponent(matches[1]) : undefined
    },
    set(key, value, options = {}) {
        options = { path: "/", ...options }

        if (typeof options.expires === "number") {
            options.expires = new Date(Date.now() + options.expires * 1000)
        }

        let updatedCookie = encodeURIComponent(key) + "=" + encodeURIComponent(value)

        for (const optionKey in options) {
            updatedCookie += "; " + optionKey
            const optionValue = options[optionKey]
            if (optionValue !== true) {
                updatedCookie += "=" + optionValue
            }
        }

        document.cookie = updatedCookie
    },
    remove(key, options = {}) {
        return cookie.set(key, "", { ...options, "max-age": -1, expires: -1 })
    },
}

export default cookie
