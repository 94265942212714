<template>
    <div class="form-filter">
        <form @submit.prevent="onSubmit()">
            <v-row dense>
                <v-col cols="12" md="4" class="pt-0 form-filter__autocomplete">
                    <v-row dense>
                        <v-col class="pb-0 pt-0">
                            <slot name="autocomplete"></slot>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="6" sm="9" class="ml-md-auto form-filter__date form-filter__relative pb-0 pt-0 ">
                    <v-row dense>
                        <v-col cols="12" sm="5" class="pb-0 pt-0">
                            <v-datetime-picker
                                color="blue-grey lighten-1"
                                class="form-filter__timepicker"
                                :label="$t(`labels['range start']`)"
                                v-model="model.begin"
                                date-format="dd.MM.yyyy"
                                :clear-text="$t(`actions.discard`)"
                                :ok-text="$t(`actions.apply`)"
                                tabs-slider-color="orange"
                                tabs-background-color="blue-grey lighten-5"
                                ok-color="primary"
                                :max="beginMax"
                                :time-picker-props="getTimePickerProps"
                                :date-picker-props="getDatePickerProps">
                                <template slot="dateIcon">
                                    <v-icon>mdi-calendar-month</v-icon>
                                </template>
                                <template slot="timeIcon">
                                    <v-icon>mdi-clock-outline</v-icon>
                                </template>
                            </v-datetime-picker>
                        </v-col>
                        <v-col cols="10" sm="5" class="pb-0 pt-0">
                            <v-datetime-picker
                                color="blue-grey lighten-1"
                                class="form-filter__timepicker"
                                :label="$t(`labels['range end']`)"
                                v-model="model.end"
                                date-format="dd.MM.yyyy"
                                :clear-text="$t(`actions.discard`)"
                                :ok-text="$t(`actions.apply`)"
                                tabs-slider-color="orange"
                                tabs-background-color="blue-grey lighten-5"
                                ok-color="primary"
                                :min="endMin"
                                :max="endMax"
                                :time-picker-props="getTimePickerProps"
                                :date-picker-props="getDatePickerProps">
                                <template slot="dateIcon">
                                    <v-icon>mdi-calendar-month</v-icon>
                                </template>
                                <template slot="timeIcon">
                                    <v-icon>mdi-clock-outline</v-icon>
                                </template>
                            </v-datetime-picker>
                        </v-col>
                        <v-col cols="2" align-self="center">
                            <select-range v-model="model" @submit="onSubmitRange"/>
                        </v-col>
                    </v-row>
                    <!--                    <v-row class="ma-0">-->
                    <!--                        <filter-buttons v-model="model" @submit="onSubmit()"/>-->
                    <!--                    </v-row>-->
                </v-col>
                <v-col cols="12" md="2" sm="3" class="mr-auto pb-0 pt-3" :align="$vuetify.breakpoint.smAndDown ? 'end' : null">
                    <slot name="submit">
                        <v-btn type="submit" outlined ripple color="blue-grey darken-1">{{ $t("actions.apply") }}</v-btn>
                    </slot>
                </v-col>
            </v-row>
        </form>
    </div>
</template>

<script>
import dayjs from "dayjs"
import unobserve from "../util/unobserve"
import SelectRange from "./SelectRange"

const isEqual = (val, compare) => JSON.stringify(val) === JSON.stringify(compare)

export default {
    name: "FormFilter",
    components: { SelectRange },
    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            model: Object.assign({ begin: null, end: null }, this.value),
        }
    },
    computed: {
        getTimePickerProps() {
            return {
                format: "24hr",
            }
        },
        getDatePickerProps() {
            return {
                locale: "ru-Latn",
                max: new Date().toISOString(),
                min: new Date(2019, 1, 1).toISOString(),
                firstDayOfWeek: "1",
            }
        },

        beginMax() {
            return this.model.end || new Date()
        },

        endMin() {
            return this.model.begin || null
        },

        endMax() {
            return dayjs().endOf("day").toDate()
        },
    },

    watch: {
        model: {
            handler() {
                this.$emit("input", this.model)
            },
            deep: true,
        },

        value: {
            handler(value) {
                if (!isEqual(value, this.model)) {
                    this.model = Object.assign({ begin: null, end: null }, value)
                }
            },
            deep: true,
        },
    },

    methods: {
        onSubmit() {
            const filter = unobserve(this.model)

            if (filter.begin) {
                filter.begin = dayjs(filter.begin).toDate()
            }

            if (filter.end) {
                filter.end = dayjs(filter.end).toDate()
            }

            this.$emit("submit", filter)
        },

        onSubmitRange({ begin, end }) {
            const filter = unobserve(this.model)
            filter.begin = begin
            filter.end = end
            this.model = filter

            this.$nextTick(() => this.$emit("submit", filter))
        },
    },
}
</script>

<style>
    .form-filter {

    }

    .form-filter__relative {
        position: relative;
    }

    .form-filter__button .v-messages__message {
        color: #303f9f;
        text-decoration: underline;
    }

    .form-filter__button .v-messages__message:hover {
        cursor: pointer;
        text-decoration: none;
    }
</style>
