<template>
    <div>
        <div class="subtitle-1 font-weight-bold blue-grey lighten-1 white--text py-2">
            <div class="mb-2 px-4">{{title}}</div>
            <v-divider dark/>
        </div>

        <v-date-picker :range="range" :min="min" :max="max" v-model="editedValue" color="blue-grey lighten-1">
            <v-btn v-if="clearable" text color="grey lighten-1" @click="onClickClearDates()">{{locale.t(`actions.discard`)}}</v-btn>
            <v-spacer></v-spacer>
            <v-btn text color="blue-grey" @click="handleCancelClick()">{{ locale.t("actions.close") }}</v-btn>
            <v-btn text color="primary" :disabled="isDisabledSubmit" @click="handleSubmitClick()">{{ locale.t("actions.ok") }}</v-btn>
        </v-date-picker>
    </div>
</template>

<script>
import Confirmable from "vuedl/src/mixins/confirmable"
import { i18n } from "../plugins/i18n"

export default {
    layout: ["default", { width: 290 }],
    mixins: [Confirmable],
    props: {
        value: [String, Array],
        range: Boolean,
        min: String,
        max: String,
        clearable: Boolean,
    },

    data() {
        return {
            editedValue: this.value,
        }
    },

    computed: {
        isDisabledSubmit() {
            if (!this.range) {
                return !this.editedValue
            }
            return this.editedValue.length !== 2
        },
        locale(){
            return i18n
        }
    },

    methods: {
        onClickClearDates() {
            this.$emit("submit", "clear")
        },

        handleCancelClick() {
            this.$emit("submit", null)
        },

        handleSubmitClick() {
            this.$emit("submit", this.editedValue)
        },
    },
}
</script>
