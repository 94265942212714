<template>
    <div id="app">
        <component :is="getLayout">
            <router-view></router-view>
        </component>
    </div>
</template>

<script>
import Layouts from "./layouts"

export default {
    name: "app",
    components: {},
    computed: {
        getLayoutName() {
            const matched = this.$route.matched

            if (matched.length === 0) {
                return "empty"
            }

            const { components } = matched[0]
            return components.default.layout || "default"
        },

        getLayout() {
            return Layouts[this.getLayoutName]
        },
    },
}
</script>
<style lang="stylus">
    .v-data-table-header .v-data-table-header__icon {
        vertical-align: unset;
    }

    .gray-tabs .v-tab {
        color #78909C !important
    }

    .gray-tabs .v-tab--active {
        color #263238 !important
    }
</style>
