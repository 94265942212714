export const en = (count, choicesLength) => {
    return count === 1 ? 0 : choicesLength - 1
}

export const ru = (count, choicesLength) => {
    if (count % 10 === 1 && count % 100 !== 11) return 0
    if (count === (count | 0) && count % 10 >= 2 && count % 10 <= 4 && (count % 100 < 12 || count % 100 > 14)) return 1
    if (count % 10 === 0 || count === (count | 0) && count % 10 >= 5 && count % 10 <= 9 || count === (count | 0) && count % 100 >= 11 && count % 100 <= 14) return 2
    return choicesLength - 1
}

const map = { ru, en }

export const applyI18n = (i18n, code) => {
    i18n.getChoiceIndex = map[code] || en
}
