<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-app>
        <div class="layout__progress" v-show="isLoadingPage">
            <v-progress-linear indeterminate color="deep-orange"/>
        </div>
        <drawer class="layout__drawer" v-model="drawer"/>

        <v-app-bar class="app-toolbar" app flat style="background: #eee; min-height: 56px">

            <v-app-bar-nav-icon size="32"
                                color="black"
                                @click="drawer = !drawer"
                                v-show="$vuetify.breakpoint.mdAndDown"/>

            <v-toolbar-title class="pa-0 text-no-wrap">
                <span v-if="toolbarTitle">{{ toolbarTitle }}</span>
                <portal-target name="title"></portal-target>
            </v-toolbar-title>

            <template v-if="usePortal && $vuetify.breakpoint.smAndDown" v-slot:extension>
                <portal-target name="toolbar"></portal-target>
            </template>

            <div v-if="$vuetify.breakpoint.mdAndUp" class="toolbar-portal"
                 :style="{height: `${$vuetify.application.top}px`}">
                <portal-target name="toolbar"></portal-target>
            </div>
        </v-app-bar>

        <v-content>
            <slot></slot>
        </v-content>
    </v-app>
</template>

<script>
import { mapGetters } from "vuex"
import Drawer from "../components/Drawer"
import debounce from "storemoodify/util/debounce"
import { Wormhole } from "portal-vue"


export default {
    components: { Drawer },
    data() {
        return {
            drawer: false,
            toolbarTitle: "",
        }
    },
    watch: {
        "$route": {
            handler() {
                this.debounceUpdateToolbarTitle()
            },
            immediate: true,
        },
    },

    computed: {
        ...mapGetters("common", ["isLoadingPage"]),
        usePortal() {
            return Wormhole.hasContentFor("toolbar")
        },
    },

    methods: {
        debounceUpdateToolbarTitle() {
            if (!this.$updateToolbarTitle) {
                this.$updateToolbarTitle = debounce(() => this.updateToolbarTitle(), 10)
            }
            this.$updateToolbarTitle()
        },

        updateToolbarTitle() {
            const route = this.$route
            try {
                this.toolbarTitle = route.matched[0].instances.default.$metaInfo.toolbar
            } catch (e) {
                return ""
            }
        },
    },
}
</script>
<style lang="stylus">
@require '../../node_modules/storemoodify.css/dist/main.min.css';
.layout__progress {
  position: fixed;
  z-index: 10;
  height: 4px;
  width: 100%;
  top: 0;
  left: 0;
}

.app-toolbar .v-toolbar__content {
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
}

.app-toolbar .v-toolbar__title {
  min-width: 145px;
  max-width: 260px;
}

.app-toolbar .toolbar-portal {
  flex: 1;
  width: 100%;
  height: 100%;
  overflow hidden
}

.toolbar-portal .vue-portal-target,
.toolbar-portal .v-tabs,
.toolbar-portal .v-tabs-bar {
  height: 100%;
}

.toolbar-portal .v-slide-group {
  margin-left 16px
}

.toolbar-portal .v-slide-group.v-slide-group--has-affixes {
  padding-left 0
}
</style>
