import Vue from "vue"
import VueI18n from "vue-i18n"
import store from "../store"
import { applyI18n } from "../util/plural"

Vue.use(VueI18n)

const locale = store.getters.getLocale
const translations = require(`../locales/${locale}.json`)

const importMessages = (code) => import(`../locales/${code}.json`).then(module => module.default)

const watchLocale = (i18n, store) => {
    const onChangeLocale = (code) => importMessages(code).then(messages => {
        i18n.setLocaleMessage(code, messages)
        applyI18n(i18n, code)
        i18n.locale = code
    })

    store.watch(() => store.getters.getLocale, onChangeLocale)
}

export const i18n = new VueI18n({
    locale: locale,
    messages: { [locale]: translations },
})

applyI18n(i18n, locale)

watchLocale(i18n, store)
