<template>
    <div>
        <autocomplete v-model="model"
                      :label="$t(`labels['choose place']`)"
                      :items="places"
                      :multiple="multiple"
                      :loading="isLoading"
                      :to-option="placeToOption"
                      @change="onChangeQuery"/>
    </div>
</template>
<script>
import { mapActions } from "vuex"
import * as Format from "../../util/format"
import unobserve from "../../util/unobserve"
import Autocomplete from "../../components/Autocomplete"

export default {
    components: { Autocomplete },
    props: {
        value: [Array, Object],
        allow: { type: Array, default: null },
        multiple: Boolean,
    },
    data() {
        return {
            model: !this.value ? this.value : unobserve(this.value),
            places: [],
            isLoading: false,
            requestId: 0,
        }
    },
    watch: {
        model() {
            this.$emit("input", this.model)
        },
        value() {
            this.model = this.value
        },
    },
    methods: {
        ...mapActions("place", ["findByQuery"]),
        placeToOption(place) {
            return { id: place.ID, name: Format.getName(place.Name) }
        },

        filterPlaces(places) {
            if (this.allow === null) {
                return places
            }

            return places.filter((place) => this.allow.includes(place.ID))
        },

        onChangeQuery(q) {
            this.isLoading = true
            this.requestId++
            const { requestId } = this
            this.findByQuery(q).then(places => {
                if (this.requestId === requestId) {
                    this.isLoading = false
                    this.places = this.filterPlaces(places)
                }
            })
        },
    },
}
</script>
