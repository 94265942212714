<template>
    <div class="layout__error">
        {{error.statusCode}}<br>
        {{error.message}}
    </div>
</template>
<script>
export default {
    layout: 'empty',
    props: {
        error: {
            type: [Error, Object],
            default: () => ({})
        }
    }
}
</script>
