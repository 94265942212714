import dayjs from "dayjs"
// eslint-disable-next-line no-unused-vars
import { GET_JSON } from "../util/resource"

const responseAdapter = (response, admins) => {
    response.collection = response.collection
        .map((item, i) => ({ statistic: item, admin: admins[i] }))
        .filter(item => item.admin !== null)
    return response
}

const defaultParams = () => {
    const prevMonth = dayjs().subtract(1, "month")
    return {
        begin: prevMonth.startOf("month").toDate(),
        end: prevMonth.endOf("month").toDate(),
        offset: 0,
    }
}

export const namespaced = true

export const state = () => ({
    top: {
        places: [],
        photographers: [],
        admins: [],
    },
})

export const getters = {
    getTop(state) {
        return state.top
    },
}

export const mutations = {
    setTop(state, top) {
        state.top = top
    },
}

export const actions = {
    storeTop({ commit, dispatch }) {
        return Promise.all([
            dispatch("place/fetchCollection", Object.assign({ orderBy: "PhotosStatistic.sales desc" }, defaultParams()), { root: true }),
            dispatch("photographer/fetchCollection", Object.assign({ orderBy: "PhotosStatistic.made desc" }, defaultParams()), { root: true }),
            GET_JSON("/users/admins", defaultParams())
                .then(res => dispatch('injectAdmin', res)),
        ]).then(([places, photographers, admins]) => {
            commit("setTop", {
                places: places.collection,
                photographers: photographers.collection,
                admins: admins.collection,
            })
        })
    },
    injectAdmin(ctx, statistic) {
        const adminIds = statistic.collection.map(i => i.user)
        return ctx.dispatch("photographer/getPhotographers", adminIds, { root: true })
            .then(admins => responseAdapter(statistic, admins))
    }
}
