<template>
    <v-menu>
        <template v-slot:activator="{ on: menu }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                    <v-btn icon v-on="{ ...tooltip, ...menu }">
                        <v-icon>mdi-calendar</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t(`actions['specify range']`) }}</span>
            </v-tooltip>
        </template>
        <v-list>
            <v-subheader>{{ $t(`headers['show range']`) }}</v-subheader>
            <v-list-item @click="onTodayClick()" v-show="!disabledDays">
                <v-list-item-title>{{ $t(`ranges['today']`) }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="onYesterdayClick()" v-show="!disabledDays">
                <v-list-item-title>{{ $t(`ranges['yesterday']`) }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="onWeekClick()">
                <v-list-item-title>{{ $t(`ranges['week']`) }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="onLastWeekClick()">
                <v-list-item-title>{{ $t(`ranges['previous week']`) }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="onCurrentWeekClick()">
                <v-list-item-title>{{ $t(`ranges['current week']`) }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="onMonthClick()">
                <v-list-item-title>{{ $t(`ranges['month']`) }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="onLastMonthClick()">
                <v-list-item-title>{{ $t(`ranges['previous month']`) }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="onCurrentMonthClick()">
                <v-list-item-title>{{ $t(`ranges['current month']`) }}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>


    <!--    <div class="filter-buttons ma-0">-->
    <!--        <v-input class="filter-buttons__hint" :hint="$t(`labels['show for']`)" persistent-hint/>-->
    <!--        <v-input class="filter-buttons__hint form-filter__button"-->
    <!--                 :hint="$t(`labels['today']`)"-->
    <!--                 @click.native="onTodayClick()"-->
    <!--                 persistent-hint/>-->
    <!--        <v-input class="filter-buttons__hint" hint="/" persistent-hint/>-->
    <!--        <v-input class="filter-buttons__hint form-filter__button"-->
    <!--                 :hint="$t(`labels['yesterday']`)"-->
    <!--                 @click.native="onYesterdayClick()"-->
    <!--                 persistent-hint/>-->
    <!--        <v-input class="filter-buttons__hint" hint="/" persistent-hint/>-->
    <!--        <v-input class="filter-buttons__hint form-filter__button"-->
    <!--                 :hint="$t(`labels['week']`)"-->
    <!--                 @click.native="onWeekClick()"-->
    <!--                 persistent-hint/>-->
    <!--        <v-input class="filter-buttons__hint" hint="/" persistent-hint/>-->
    <!--        <v-input class="filter-buttons__hint form-filter__button"-->
    <!--                 @click.native="onMonthClick()"-->
    <!--                 :hint="$t(`labels['month']`)"-->
    <!--                 persistent-hint/>-->
    <!--        <v-input class="filter-buttons__hint" hint="/" persistent-hint/>-->
    <!--        <v-input class="filter-buttons__hint form-filter__button"-->
    <!--                 @click.native="onAllClick()"-->
    <!--                 hint="всё время"-->
    <!--                 persistent-hint/>-->
    <!--    </div>-->
</template>

<script>
import dayjs from "dayjs"

export default {
    props: {
        disabledDays: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            model: this.value,
        }
    },
    methods: {
        onTodayClick() {
            this.setRange(
                dayjs().startOf("day").toDate(),
                dayjs().toDate(),
            )
        },

        onYesterdayClick() {
            const yesterday = dayjs().subtract(1, "day").startOf("day")
            this.setRange(
                yesterday.toDate(),
                yesterday.endOf("day").toDate(),
            )
        },

        onWeekClick() {
            this.setRange(
                dayjs().subtract(1, "week").startOf("day").toDate(),
                dayjs().toDate(),
            )
        },

        onLastWeekClick() {
            const weekStart = dayjs().subtract(1, "week").startOf("week")
            this.setRange(
                weekStart.toDate(),
                weekStart.endOf("week").toDate(),
            )
        },

        onCurrentWeekClick() {
            this.setRange(
                dayjs().startOf("week").toDate(),
                dayjs().toDate(),
            )
        },

        onMonthClick() {
            this.setRange(
                dayjs().subtract(1, "month").startOf("day").toDate(),
                dayjs().toDate(),
            )
        },

        onLastMonthClick() {
            const monthStart = dayjs().subtract(1, "month").startOf("month")

            this.setRange(
                monthStart.toDate(),
                monthStart.endOf("month").toDate(),
            )
        },

        onCurrentMonthClick() {
            this.setRange(
                dayjs().startOf("month").toDate(),
                dayjs().toDate(),
            )
        },

        onAllClick() {
            this.setRange(null, null)
        },

        setRange(begin, end) {
            this.$nextTick().then(() => {
                const value = { begin: new Date(begin), end: new Date(end) }
                this.$emit("input", value)
                this.$emit("submit", value)
            })
        },
    },
}
</script>

<style>
    .filter-buttons {
        position: relative;
        top: -12px;
        font-size: 0;
        white-space: nowrap;
    }

    .filter-buttons__hint {
        display: inline-block;
        margin-right: 4px;
    }

    @media (max-width: 380px) {
        .filter-buttons {
            white-space: initial;
        }
    }
</style>
