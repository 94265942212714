import URL from "url"
import shortId from "storemood-shortid"
import Connection from "../plugins/connection"
import { GET_JSON } from "../util/resource"

const {
    GetPlacePromoQR,
    GetDailyQR,
    PhotogroupQRWithPlace,
    GetPlaceQR,
    PhotogroupQRWithPhotographer,
    GetPhotographerQR,
} = Connection.getService("Promo").mapRequests([
    "GetPlacePromoQR",
    "GetDailyQR",
    "PhotogroupQRWithPlace",
    "GetPlaceQR",
    "PhotogroupQRWithPhotographer",
    "GetPhotographerQR",
])

const { GetPromoCode, ActivatePromoCode } = Connection.getService("Billing").mapRequests(["GetPromoCode", "ActivatePromoCode"])

export const namespaced = true

export const actions = {
    extractCardCode(ctx, value) {
        const parsed = URL.parse(value)
        parsed.query = Object.assign(parsed.query || {}, { noredirect: true })

        return GET_JSON(URL.format(parsed))
            .then((res) => res.url)
            .then((url) => shortId.findOne(url, shortId.TYPES.PHOTOGROUP))
            .catch(() => null)
    },

    createPromoCodes(ctx, { placeId, count, date }) {
        return GetPlacePromoQR({
            PlaceID: placeId,
            Count: count,
            Type: 1,
            Date: date,
        }).then(({ Result }) => Result)
    },

    createPlaceCardCodes(ctx, { count, placeId }) {
        return PhotogroupQRWithPlace({ PlaceID: placeId, Count: count })
            .then(({ Results }) => Results)
    },

    createPhotographerCardCodes(ctx, { photographerId, count }) {
        return PhotogroupQRWithPhotographer({
            PhotographerID: photographerId,
            Count: count,
        }).then(({ Results }) => Results)
    },

    createPhotographerBadgeCode(ctx, photographerId) {
        return GetPhotographerQR({
            PhotographerID: photographerId,
            Count: 1,
        }).then(({ Urls }) => Urls[0])
    },

    createPlaceBadgeCode(ctx, { placeId, photographerId }) {
        return GetPlaceQR({
            PlaceID: placeId,
            PhotographerID: photographerId,
            Count: 1,
        }).then(({ Urls }) => Urls[0])
    },

    createDailyCode(ctx, { placeId, date }) {
        return GetDailyQR({ PlaceID: placeId, Date: date })
    },

    // Activate promo code by admin
    activateCode(ctx, { code, activation }) {
        return ActivatePromoCode({
            Code: code,
            ActivationCode: activation,
        })
    },

    // Decode short url code
    decode(ctx, code) {
        return GET_JSON(code, { noredirect: true })
            .then(({ url }) => URL.parse(url, true).query.q || null)
    },

    findCode(ctx, code) {
        return GetPromoCode({ Code: code })
    },
}
