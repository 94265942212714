const parseOptions = (value) => {
    const defaults = { maxKeys: 100, ttl: 5000 };

    if (typeof value === "number") {
        return { maxKeys: 100, ttl: value }
    } else if (typeof value === 'object' && value !== null) {
        return Object.assign(defaults, value)
    }

    return defaults
};

export default class Cache {
    constructor(options = {}) {
        const opts = parseOptions(options);
        this.store = new Map();
        this.ttl = opts.ttl;
        this.maxKeys = opts.maxKeys
    }

    put(key, data, ttl = 0) {
        if (this.getSize() === this.maxKeys) {
            this.flushUnused()
        }

        const usedAt = Date.now();
        const expiredAt = usedAt + (ttl || this.ttl);
        this.updateFlushTimeout();
        this.store.set(key, { usedAt, expiredAt, data });
        return this
    }

    get(key) {
        const value = this.store.get(key);
        if (!value) {
            return null
        }

        value.usedAt = Date.now();
        return value.data
    }

    getSize() {
        return this.store.size
    }

    flush() {
        this.store.clear();
        return this
    }

    flushUnused() {
        let lastUsingTime = Date.now() + 1;
        let lastUsingKey = null;

        this.store.forEach(({ usedAt }, key) => {
            if (lastUsingTime > usedAt) {
                lastUsingTime = usedAt;
                lastUsingKey = key
            }
        });
        if (lastUsingKey !== null) {
            this.deleteByKey(lastUsingKey)
        }
    }

    updateFlushTimeout() {
        if (!this.flushTimeout) {
            this.flushTimeout = setTimeout(() => this.flushExpired(), this.ttl)
        }
    }

    deleteByKey(key) {
        const item = this.store.get(key);
        if (item && item.ondelete) {
            item.ondelete();
        }

        this.store.delete(key);
    }

    flushExpired() {
        const now = Date.now();
        let tillFirstExpire = this.ttl;

        this.store.forEach(({ expiredAt }, key) => {
            if (now + 10 >= expiredAt) {
                this.deleteByKey(key);
                return
            }

            const tillExpire = expiredAt - now;

            if (tillExpire < tillFirstExpire) {
                tillFirstExpire = tillExpire
            }
        });

        if (this.getSize() !== 0) {
            this.flushTimeout = setTimeout(() => this.flushExpired(), tillFirstExpire)
        } else {
            this.flushTimeout = null
        }
    }
}
