export default {
    locales: ["ru", "en"],
    cookie: {
        domain: "localhost",
        expires: 60 * 60 * 24 * 365,
    },
    rpc: "wss://storemood.com/ws/rpc",
    storage: "https://data.storemood.com/",
    upload: "https://storemood.com/api/upload/photo",
    uploadPlaceLogo: "https://storemood.com/api/upload/logo",
    uploadPlaceWallpaper: "https://storemood.com/api/upload/place-wallpaper",
    uploadAvatar: "https://storemood.com/api/upload/avatar",
    uploadUserWallpaper: "https://storemood.com/api/upload/wallpaper",
}
