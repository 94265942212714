<template>
    <div class="place-filter">
        <base-filter v-model="model" @submit="onSubmit()">
            <template slot="autocomplete">
                <photographer-autocomplete multiple v-model="model.photographers"/>
            </template>
        </base-filter>
    </div>
</template>

<script>
import Filter from '../../components/Filter'
import PhotographerAutocomplete from './Autocomplete'

export default {
    components: { BaseFilter: Filter, PhotographerAutocomplete },
    name: 'PlaceFilter',

    props: {
        value: {
            type: Object,
            default: () => ({})
        }
    },

    data() {
        return {
            model: Object.assign({ photographers: []}, this.value)
        }
    },

    watch: {
        model: {
            handler() {
                this.$emit('input', this.model);
            },
            deep: true
        },

        value: {
            handler(value) {
                this.model = value;
            },
            deep: true
        }
    },

    methods: {
        onSubmit() {
            this.$emit('submit');
        }
    }
}
</script>
