import config from "config"
import Connection from "../plugins/connection"
import Resource from "../util/resource"
import Cookie from "../util/cookie"

// eslint-disable-next-line no-unused-vars
const SUPPORT_SW = "serviceWorker" in navigator
const { Hello } = Connection.getService("User").mapRequests(["Hello"])
const IdentityService = Connection.getService("Identity")

// eslint-disable-next-line no-unused-vars
const { CancelAuthentication, Verify, StartSignIn } = IdentityService.mapRequests(["CancelAuthentication", "Verify", "Start", "StartSignIn"])

const noop = () => {
    // Do nothing
}

const onConnectionStatus = (payload, dispatch) => {
    const status = payload || {}
    const code = status.code || 0
    const metadata = status.metadata || {}
    const sessionToken = metadata.authorization || null
    if (code === 0 && sessionToken) {
        dispatch("setAuthorization", sessionToken)
    }
}

const isPhone = value => new RegExp("^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\\s\\./0-9]*$", "g").test(value || "")

export const namespaced = true

export const state = () => ({
    showUpcoming: true,
    user: null,
    isAuthorized: false,
    authorization: null,
    loadingPage: false,
    photoCosts: [350, 320, 280, 250, 220],
})

export const getters = {
    getUser(state) {
        return state.user
    },
    getUserId(state, getters) {
        return (getters.getUser || {}).ID || null
    },
    showUpcoming(state) {
        return state.showUpcoming
    },
    getAuthorization(state) {
        return state.authorization
    },
    isAuthorized(state) {
        return !!state.user
    },
    isLoadingPage(state) {
        return state.loadingPage
    },
    isPlaceAdmin(state) {
        const adminingPlaces = (state.user || {}).AdminingPlaces || []
        return adminingPlaces.length !== 0
    },
    isPhotographer(state) {
        return !!(state.user || {}).IsPhotographer
    },
    isJesus(state) {
        return !!(state.user || {}).JS
    },
    isSeller(state){
        const { SellerPlaces } = state.user || {}
        return (SellerPlaces || []).length !== 0
    },
    getPhotoCosts(state) {
        return state.photoCosts
    },
    getLocale() {
        return "ru"
    },
}

export const mutations = {
    setUser(state, user) {
        state.user = user
    },
    setUpcoming(state, flag) {
        state.showUpcoming = flag
    },
    setAuthorization(state, authorization) {
        state.authorization = authorization
    },
    setLoadingPage(state, loading) {
        state.loadingPage = loading
    },
    setPhotoCosts(state, costs) {
        state.photoCosts = costs.map(c => parseFloat(c))
    },
}

export const actions = {
    fetchUser({ commit }) {
        return Hello().then(({ User, JS, PhotoCosts }) => {
            // eslint-disable-next-line no-console
            commit("setPhotoCosts", PhotoCosts)
            if (User) {
                commit("setUser", Object.assign(User, { JS }))
            }
            return User
        })
    },

    hideUpcoming({ commit }) {
        commit("setUpcoming", false)
    },

    logout({ dispatch, commit }) {
        dispatch("setAuthorization", null)
        commit("setUser", null)
    },

    signIn({ dispatch }, { identity, password }) {
        dispatch("setAuthorization", null)
        const verifyRequest = {
            Identity: identity,
            IdentityName: isPhone(identity) ? "phone" : "email",
            VerificationCode: password,
            VerifierName: "password",
        }

        return CancelAuthentication()
            .then(() => StartSignIn())
            .then(() => Verify(verifyRequest))
            .then(() => dispatch("fetchUser"))
    },

    setAuthorization({ commit, dispatch }, authorization = null) {
        commit("setAuthorization", authorization)
        Cookie.set("sessid", authorization || "", {
            path: "/",
            domain: config.cookie.domain,
            expires: config.cookie.expires,
        })

        dispatch("updateAuthorization")
    },

    updateAuthorization() {
        const authorization = Cookie.get("sessid")
        const clientId = "manage.storemood.com"
        Connection.configure({ metadata: { authorization, "client-id": clientId } })
        Resource.setAuthorization(authorization)
    },

    startApp({ dispatch }) {
        const authorization = Cookie.get("sessid")
        dispatch("setAuthorization", authorization)
        dispatch("installWorkers")
        Connection.on("status", (status) => onConnectionStatus(status, dispatch))
        Connection.on("pre:send", () => dispatch("updateAuthorization"))
        return dispatch("fetchUser")
    },

    installWorkers() {
        if (!SUPPORT_SW || process.env.NODE_ENV === "development") {
            return
        }

        navigator.serviceWorker.register("/cache.sw.js?v=3", { scope: "/" })
            .then(noop)
            .catch(noop)
    },
}
