<template>
    <v-card tile>
        <v-toolbar>
            <v-toolbar-title>{{title}}</v-toolbar-title>
            <div class="vuedl-layout__closeBtn" @click.stop="onClickClose">
                ×
            </div>
        </v-toolbar>

        <div class="px-2 mt-4">
            <v-text-field v-model="query" solo-inverted
                          :label="locale.t(`labels['find photographer']`)"></v-text-field>
        </div>

        <div v-if="loading" class="user-autocomplete__loading d-flex align-center justify-center">
            <v-progress-circular
                :size="50"
                color="blue-grey darken-4"
                indeterminate
            ></v-progress-circular>
        </div>

        <div class="user-autocomplete__list" v-show="canDisplayList">
            <v-list subheader>
                <v-subheader>{{ locale.t(`headers['found users']`) }}</v-subheader>
                <v-list-item @click="onClickUser(user.user)" v-for="user in getList" :key="user.id">
                    <v-list-item-avatar>
                        <v-img :src="user.avatar"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title v-text="user.name"></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </div>

        <div class="user-autocomplete__empty" v-show="canDisplayEmpty">
            <p class="text-center subtitle-1 my-6">
                {{ locale.t(`messages['users not found']`) }}
            </p>
        </div>

        <v-card-actions>
            <v-spacer/>
            <DialogActions :actions="actions" flat/>
        </v-card-actions>
    </v-card>
</template>

<script>
import debounce from "storemoodify/util/debounce"
import Confirmable from "vuedl/src/mixins/confirmable"
import Format from "../util/format"
import store from "../store"
import { i18n } from "../plugins/i18n"

export default {
    layout: ["default", { width: 520 }],
    mixins: [Confirmable],
    props: {
        params: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            query: "",
            loading: false,
            list: [],
        }
    },

    computed: {
        getList() {
            return this.list.map(user => ({
                name: Format.getUserName(user.Name),
                avatar: Format.getMedia(user.Avatar.Cropped),
                id: user.ID,
                user,
            }))
        },

        locale() {
            return i18n
        },

        canDisplayList() {
            return !this.loading && this.list.length !== 0
        },

        canDisplayEmpty() {
            return !this.loading && this.list.length === 0
        },
    },

    watch: {
        query() {
            this.loading = true
            this.clearList()
            this.onChangeQuery()
        },
    },

    created() {
        this.loadList()
        this.onChangeQuery = debounce(() => this.loadList(), 500)
    },

    methods: {
        clearList() {
            this.list = []
        },

        loadList() {
            this.loading = true
            this.getUsers().then(res => {
                this.loading = false
                this.list = res
            })
        },

        getUsers() {
            return store.dispatch("user/findByQuery", {
                query: this.query,
                params: Object.assign({}, this.params, { Limit: 20 }),
            })

        // return store.dispatch("photographer/findByQuery", {
        //     query: this.query,
        //     params: Object.assign({}, this.params, { Limit: 20 }),
        //   })
        },

        onClickUser(user) {
            this.$emit("submit", user)
        },

        onClickClose() {
            this.$emit("submit", null)
        },
    },
}
</script>
<style>
    .user-autocomplete__list {
        max-height: 250px;
        overflow: auto;
    }
</style>
