<template>
    <data-table
        class="place-table"
        v-model="getValue"
        :headers="headers"
        :loading="loading"
        :options.sync="opts"
        hide-default-footer
        ref="table"
    >
        <template slot="body.prepend" v-if="!loading">
            <tr v-if="getValue.collection.length > 1">
                <td class="place-table__name" >
                    <span class="title" v-if="extended">{{ $t(`tables['total']`) }}</span>
                </td>
                <td class="subtitle-1 font-weight-bold" v-if="extended">{{ sumField("PhotosStatistic.sales") }}</td>

                <td class="subtitle-1 font-weight-bold" v-if="extended">{{ sumField("PhotosStatistic.salesAmountCash") | currency }}</td>
                <td class="subtitle-1 font-weight-bold" v-if="extended">{{ sumField("PhotosStatistic.salesAmountPromo") | currency }}</td>

                <td class="subtitle-1 font-weight-bold" v-if="promo !== false">{{ sumField("viewPhotosByWorksheet") }}</td>
                <td class="subtitle-1 font-weight-bold" v-if="promo !== false">{{ sumField("viewPhotosByQR") }}</td>
                <td class="subtitle-1 font-weight-bold" v-if="promo !== false">{{ sumField("PromoStatistic.WorksheetsSite") }}
                </td>
                <td class="subtitle-1 font-weight-bold" v-if="promo !== false">{{ sumField("PromoStatistic.WorksheetsApp") }}
                </td>

                <td class="subtitle-1 font-weight-bold" v-if="extended">{{ sumField("PhotosStatistic.salesRatio") | fixedNumber }}</td>

                <td class="subtitle-1 font-weight-bold">{{ sumField("PhotosStatistic.uploaded") }}</td>

                <td class="subtitle-1 font-weight-bold">{{ sumField("viewed") }}</td>

                <td class="subtitle-1 font-weight-bold" v-if="promo !== false">
                    {{ sumField("PromoStatistic.created") }}
                </td>
                <td class="subtitle-1 font-weight-bold" v-if="promo !== false">
                    {{ sumField("PromoStatistic.activated") }}
                </td>
                <td class="subtitle-1 font-weight-bold" v-if="promo !== false">
                    {{ sumField("PromoStatistic.happened") }}
                </td>
                <td class="subtitle-1 font-weight-bold" v-if="promo !== false">
                    {{ sumField("PromoStatistic.registered") }}
                </td>

                <td class="subtitle-1 font-weight-bold">{{ sumField("PhotoGroupsStatistic.createdAll") }}</td>
                <td class="subtitle-1 font-weight-bold">{{ sumField("PhotoGroupsStatistic.createdManual") }}</td>
                <td class="subtitle-1 font-weight-bold">{{ sumField("PhotoGroupsStatistic.createdAutomatic") }}</td>

                <td class="subtitle-1 font-weight-bold">{{ sumField("PhotoReportsStatistic.created") }}</td>
                <td class="subtitle-1 font-weight-bold">{{ sumField("PhotoReportsStatistic.viewed") }}</td>
                <td class="subtitle-1 font-weight-bold">{{ sumField("PhotoGroupsStatistic.viewed") }}</td>
                <!--                <td class="subtitle-1 font-weight-bold">{{sumField("PhotoReportsStatistic.sales")}}</td>-->
                <!--                <td class="subtitle-1 font-weight-bold">{{sumField("PhotoReportsStatistic.salesAmountCash")}}</td>-->
                <!--                <td class="subtitle-1 font-weight-bold">{{sumField("PhotoReportsStatistic.salesAmountPromo")}}</td>-->


                <!--                <td v-if="promo !== false">{{props.item.statistic.OwnerProfit | currency}}</td>-->
                <td class="subtitle-1 font-weight-bold" v-if="promo !== false && extended">{{ sumField("LLProfit") }}</td>
                <!--                <td class="subtitle-1 font-weight-bold" v-if="promo !== false">{{sumField("PhotographersProfit")}}</td>-->
            </tr>
        </template>
        <template slot="item" slot-scope="props">
            <tr>
                <td class="place-table__name data-table__sticky_x">
                    <router-link :to="`/places/${props.item.place.ID}`">
                        {{ props.item.place.formattedName }}
                    </router-link>
                </td>
                <td v-if="extended">{{ props.item.statistic.PhotosStatistic.sales }}</td>

                <td v-if="extended">{{ props.item.statistic.PhotosStatistic.salesAmountCash | currency }}</td>
                <td v-if="extended">{{ props.item.statistic.PhotosStatistic.salesAmountPromo | currency }}</td>

                <td v-if="promo !== false">{{ props.item.statistic.viewPhotosByWorksheet }}</td>
                <td v-if="promo !== false">{{ props.item.statistic.viewPhotosByQR }}</td>
                <td v-if="promo !== false">{{ props.item.statistic.PromoStatistic.WorksheetsSite }}</td>
                <td v-if="promo !== false">{{ props.item.statistic.PromoStatistic.WorksheetsApp }}</td>

                <td v-if="extended">{{ props.item.statistic.PhotosStatistic.salesRatio | fixedNumber }}</td>

                <td>{{ props.item.statistic.PhotosStatistic.uploaded }}</td>

                <td>{{ props.item.statistic.viewed }}</td>

                <td v-if="promo !== false">{{ props.item.statistic.PromoStatistic.created }}</td>
                <td v-if="promo !== false">{{ props.item.statistic.PromoStatistic.activated }}</td>
                <td v-if="promo !== false">{{ props.item.statistic.PromoStatistic.happened }}</td>
                <td v-if="promo !== false">{{ props.item.statistic.PromoStatistic.registered }}</td>


                <td>{{ props.item.statistic.PhotoGroupsStatistic.createdAll }}</td>
                <td>{{ props.item.statistic.PhotoGroupsStatistic.createdManual }}</td>
                <td>{{ props.item.statistic.PhotoGroupsStatistic.createdAutomatic }}</td>

                <td>{{ props.item.statistic.PhotoReportsStatistic.created }}</td>
                <td>{{ props.item.statistic.PhotoReportsStatistic.viewed }}</td>
                <td>{{ props.item.statistic.PhotoGroupsStatistic.viewed }}</td>
                <!--                <td>{{props.item.statistic.PhotoReportsStatistic.sales}}</td>-->
                <!--                <td>{{props.item.statistic.PhotoReportsStatistic.salesAmountCash | currency}}</td>-->
                <!--                <td>{{props.item.statistic.PhotoReportsStatistic.salesAmountPromo | currency}}</td>-->

                <!--                <td v-if="promo !== false">{{props.item.statistic.OwnerProfit | currency}}</td>-->
                <td v-if="promo !== false && extended">{{ props.item.statistic.LLProfit | currency }}</td>
                <!--                <td v-if="promo !== false">{{props.item.statistic.PhotographersProfit | currency}}</td>-->
            </tr>
        </template>
    </data-table>
</template>
<script>
import { $get } from "storemoodify/util/get"
import DataTable from "../DataTable"
import * as Format from "../../util/format"
import { i18n } from "../../plugins/i18n"

const TABLE_HEADERS = (visiblePromo, extended) => {
    return [
        { text: i18n.t(`tables['place']`), align: "left", sortable: false, value: "name" },

        extended ? { text: i18n.t(`tables['bought']`), value: "PhotosStatistic.sales" } : null,
        extended ? { text: i18n.t(`tables['cash']`), value: "PhotosStatistic.salesAmountCash" } : null,
        extended ? { text: i18n.t(`tables['promo']`), value: "PhotosStatistic.salesAmountPromo" } : null,

        visiblePromo !== false ? { text: i18n.t(`tables['views sheets']`), value: "viewPhotosByWorksheetCreated" } : null,
        visiblePromo !== false ? { text: i18n.t(`tables['views qr']`), value: "viewPhotosByQR" } : null,
        visiblePromo !== false ? { text: i18n.t(`tables['sheets site']`), value: "PromoStatistic.WorksheetsSite" } : null,
        visiblePromo !== false ? { text: i18n.t(`tables['sheets app']`), value: "PromoStatistic.WorksheetsApp" } : null,

        extended ?  { text: i18n.t(`tables['sales ratio']`), value: "PhotosStatistic.salesRatio" } : null,

        { text: i18n.t(`tables['uploaded']`), value: "PhotosStatistic.uploaded" },

        { text: i18n.t(`tables['viewed']`), value: "sViewed.viewed" },

        visiblePromo !== false ? { text: i18n.t(`tables['promo created']`), value: "PromoStatistic.created" } : null,
        visiblePromo !== false ? { text: i18n.t(`tables['promo activated']`), value: "PromoStatistic.activated" } : null,
        visiblePromo !== false ? { text: i18n.t(`tables['promo used']`), value: "PromoStatistic.happened" } : null,
        visiblePromo !== false ? { text: i18n.t(`tables['promo registered']`), value: "PromoStatistic.registered" } : null,

        { text: i18n.t(`tables['photoset total']`), value: "PhotoGroupsStatistic.createdAll" },
        { text: i18n.t(`tables['photoset app']`), value: "PhotoGroupsStatistic.createdManual" },
        { text: i18n.t(`tables['photoset auto']`), value: "PhotoGroupsStatistic.createdAutomatic" },

        { text: i18n.t(`tables['sessions created']`), value: "PhotoReportsStatistic.created" },
        { text: i18n.t(`tables['sessions viewed']`), value: "PhotoReportsStatistic.viewed" },
        { text: i18n.t(`tables['photosets viewed']`), value: "PhotoGroupsStatistic.viewed" },
        // { text: "Продано фотосессий", value: "PhotoReportsStatistic.sales" },

        // { text: "Сумма продаж фотосессий(наличные)", value: "PhotoReportsStatistic.salesAmountCash" },
        // { text: "Сумма продаж фотосессий(промо)", value: "PhotoReportsStatistic.salesAmountPromo" },

        // visiblePromo !== false ? {text: 'Прибыль владельца', value: 'OwnerProfit'} : null,
        visiblePromo !== false && extended ? { text: i18n.t(`tables['lord profit']`), value: "LLProfit" } : null,
    // visiblePromo !== false ? { text: "Прибыль фотографов", value: "PhotographersProfit" } : null,
    ].filter(header => header !== null)
}

export default {
    components: { DataTable },
    filters: { currency: Format.currency },
    props: {
        extended: { type: Boolean, default: false },
        value: { type: Object, default: () => ({}) },
        promo: { type: Boolean, default: true },
        loading: { type: Boolean, default: false },
        options: { type: Object, default: () => ({}) },
    },

    data() {
        return {
            // headers: TABLE_HEADERS(this.promo),
            opts: Object.assign({}, this.options),
        }
    },

    computed: {
        getValue() {
            const { collection, count } = this.value
            collection.forEach(item => {
                item.place.formattedName = Format.getName(item.place.Name)
            })
            return { collection, count }
        },
        headers() {
            return TABLE_HEADERS(this.promo, this.extended)
        },
    },

    watch: {
        opts() {
            this.$emit("update:options", this.opts)
        },
        options() {
            this.opts = this.options
        },
    },

    methods: {
        sumField(fieldName) {
            const { collection } = this.value
            const field = `statistic.${fieldName}`

            return collection.reduce((res, item) => {
                const value = $get(item, field)
                return res += isNaN(value) ? 0 : Format.currencyAsNumber(value)
            }, 0)
        },
    },
}
</script>
<style>
.place-table .place-table__name {
  min-width: 200px;
  white-space: nowrap;
}

.place-table th {
  white-space: nowrap;
}
</style>
