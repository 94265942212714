<template>
    <div>
        <portal to="toolbar">
            <v-tabs
                class="gray-tabs toolbar-tabs" slider-color="orange" background-color="blue-grey lighten-5"
                v-model="tab">

                <v-tab to="#export" v-if="canVisiblePlaces">{{ $t(`tabs['export']`) }}</v-tab>

                <v-tab to="#top">{{ $t(`tabs['top']`) }}</v-tab>

                <v-tab to="#main" v-if="canVisiblePlaces">{{ $t(`tabs['places']`) }}</v-tab>

                <v-tab to="#photographers" v-if="isJesus">{{ $t(`tabs['photographers']`) }}</v-tab>

                <v-tab v-if="isJesus" to="#source">{{ $t(`tabs['source']`) }}</v-tab>

                <v-tab v-if="isPhotographerOnly" :to="`/photographers/${getUserId}`">{{ $t(`tabs['my statistic']`) }}</v-tab>

                <!--                <v-tab v-if="isJesus" to="#income">{{ $t(`headers['income sources']`) }}</v-tab>-->
            </v-tabs>
        </portal>

        <v-tabs-items v-model="tab">

            <v-tab-item v-if="canVisiblePlaces" id="export" :transition="false" :reverse-transition="false">
                <v-container fluid style="background-color: #fff">
                    <Export />
                </v-container>
            </v-tab-item>

            <v-tab-item id="top" :transition="false" :reverse-transition="false">
                <v-container fluid style="background-color: #fff">
                    <top-list/>
                </v-container>
            </v-tab-item>

            <v-tab-item v-if="canVisiblePlaces" id="main" :transition="false" :reverse-transition="false">
                <v-container fluid style="background: #fff">
                    <place-table-controller/>
                </v-container>
            </v-tab-item>

            <v-tab-item v-if="isJesus" id="photographers" :transition="false" :reverse-transition="false">
                <v-container fluid style="background: #fff">
                    <photographer-table-controller/>
                </v-container>
            </v-tab-item>

            <v-tab-item v-if="isJesus" id="source" :transition="false" :reverse-transition="false">
                <v-container fluid style="background-color: #fff">
                    <service-reports-controller/>
                </v-container>
            </v-tab-item>
            <!--            <v-tab-item v-if="isJesus" id="income" :transition="false" :reverse-transition="false">-->
            <!--                <v-container fluid style="background-color: #fff">-->
            <!--                    <income-report-controller/>-->
            <!--                </v-container>-->
            <!--            </v-tab-item>-->
        </v-tabs-items>
    </div>
</template>

<script>
import PlaceTableController from "../components/place/TableController"
import PhotographerTableController from "../components/photographer/TableController"
import ServiceReportsController from "../components/statistics/ServiceReportsController"
import IncomeReportController from "../components/statistics/IncomeReportController"
import TopList from "../components/TopList"
import Export from '../components/statistics/Export'
// import Dynamics from "@/components/place/Dynamics"
import { mapGetters } from "vuex"

export default {
    components: {
        PlaceTableController,
        PhotographerTableController,
        ServiceReportsController,
        TopList,
        // eslint-disable-next-line vue/no-unused-components
        IncomeReportController,
        Export,
    },
    head() {
        return {
            title: this.$t("titles.statistics"),
            toolbar: this.$t("toolbar.statistics"),
        }
    },
    data() {
        return {
            tab: this.$route.hash || "#export",
        }
    },
    computed: {
        ...mapGetters("common", ["isJesus", "isPhotographer", "isPlaceAdmin", "isSeller", "getUserId"]),
        isPhotographerOnly() {
            return this.isPhotographer && !this.isJesus && !this.isPlaceAdmin && !this.isSeller
        },

        canVisiblePlaces() {
            return this.isJesus || this.isPlaceAdmin
        },
    },
}
</script>
