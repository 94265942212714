<script>
import ResizeObserver from "resize-observer-polyfill"
import debounce from "storemoodify/util/debounce"

const getSeriesKeys = (series) => series.filter(s => !!s.key).map(s => s.key)

const randId = () => "n" + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)

export default {
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        theme: {
            type: String,
            default: "mdl",
        },
        title: {
            type: String,
            default: "",
        },
        options: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            rootId: randId(),
        }
    },
    mounted() {
        this.$updateValue = debounce(() => this.onUpdateValue(), 50)
        this.$nextTick().then(() => {
            this.$chart = this.init()
            this.onUpdateValue()
            this.$watch("value", this.$updateValue, { immediate: true, deep: true })
            this.observeResize()
        })
    },
    beforeDestroy() {
        this.unobserveResize()
    },
    methods: {
        init() {
        // Do nothing
        },

        onUpdateValue() {
        // Do nothing
        },

        observeResize() {
            this.$observer = new ResizeObserver(() => this.onResize())
            this.$observer.observe(this.$el)
        },

        unobserveResize() {
            if (this.$observer) {
                this.$observer.unobserve(this.$el)
                this.$observer.disconnect()
            }
        },

        onResize() {
            this.$chart.reflow()
        },

        updateSeries(series) {
            if (!this.$chart) {
                return
            }

            const chart = this.$chart
            const keys = getSeriesKeys(series)
            let oldKeys = getSeriesKeys(chart.series)

            const isRedraw = (serie) => {
                return !(oldKeys.includes(serie.key) && serie.keepAlive && keys.includes(serie.key))
            }

            const removes = chart.series.map(serie => {
                if (!isRedraw(serie)) {
                    return null
                }

                return Promise.resolve()
                    .then(() => {
                        serie.remove()
                        oldKeys = oldKeys.filter(k => k !== serie.key)
                    })
            })

            Promise.all(removes).then(() => {
                series.forEach(s => {
                    if (isRedraw(s)) {
                        const serie = chart.addSeries(s, true)
                        serie.key = s.key
                        serie.keepAlive = s.keepAlive
                    }
                })
            })
        },
    },
}
</script>


