import Connection from "@/plugins/connection"

const {
    Find,
    FindPGByPhotos,
    SearchPG
} = Connection.getService("Photogroup").mapStreams(["Find", "FindPGByPhotos", "SearchPG"])
const {
    Create,
    SetStartTime,
    SetEndTime,
    AddQR,
    Delete,
    GetByID,
} = Connection.getService("Photogroup").mapRequests([
    "Create",
    "SetStartTime",
    "SetEndTime",
    "AddQR",
    "Delete",
    "GetByID",
])

export const namespaced = true
export const state = () => ({
    photogroup: null,
})

export const getters = {
    getPhotogroup(state) {
        return state.photogroup
    },
}

export const mutations = {
    setPhotogroup(state, photogroup) {
        state.photogroup = photogroup
    },
}

export const actions = {
    getById(ctx, id) {
        return GetByID({ ID: id })
    },

    storePhotogroup({ dispatch, commit }, id) {
        return dispatch("getById", id)
            .then(res => dispatch("injectPhotogroup", res))
            .then(photogroup => {
                commit("setPhotogroup", photogroup)
            })
    },

    find({ dispatch }, params) {
        return Find(params)
            .storeData()
            .then(res => dispatch("inject", res))
    },

    search({ dispatch }, params) {
        return SearchPG(params).storeData()
            .then(res => dispatch("inject", res))
    },

    findByPhotos({ dispatch }, photoIds) {
        return FindPGByPhotos({ PhotoIDs: photoIds })
            .storeData()
            .then(res => dispatch("inject", res))
    },

    create(ctx, attrs) {
        return Create(attrs)
    },

    inject({ dispatch }, photogroups) {
        return Promise.all(
            photogroups.map(p => dispatch("injectPhotogroup", p)),
        )
    },

    setStartTime(ctx, { id, time }) {
        return SetStartTime({ ID: id, Time: time })
    },

    setEndTime(ctx, { id, time }) {
        return SetEndTime({ ID: id, Time: time })
    },

    setTime({ dispatch }, { id, start, end }) {
        return Promise.all([
            dispatch("setStartTime", { id, time: start }),
            dispatch("setEndTime", { id, time: end }),
        ])
    },

    attachQr(ctx, { id, code }) {
        return AddQR({ ID: id, QR: code })
    },

    attachQrs({ dispatch }, { id, codes }) {
        return Promise.all(
            codes.map(code => dispatch("attachQr", { id, code })),
        )
    },

    remove(ctx, id) {
        return Delete({ ID: id })
    },

    removeMany({ dispatch }, ids) {
        return Promise.all(
            ids.map(id => dispatch("remove", id)),
        )
    },

    injectPhotogroup({ dispatch }, photogroup) {
        const { PlaceID, PhotographerID } = photogroup.Criterias
        return Promise.all([
            dispatch("place/getById", PlaceID, { root: true }),
            dispatch("user/getById", PhotographerID, { root: true }),
            dispatch("photos/getInRange", photogroup.Criterias, { root: true }),
        ]).then(([Place, Photographer, Photos]) => {
            return Object.assign(photogroup, { Place, Photographer, Photos })
        })
    },
}
