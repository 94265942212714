<template>
    <table-sticky-wrapper>
        <div class="data-table" :class="{'data-table_sm': $vuetify.breakpoint.sm}">
            <v-data-table
                loading-text=""
                dense
                must-sort
                :headers="getHeaders"
                :items="getItems"
                :options.sync="opts"
                :loading="loading"
                :server-items-length="getItemsCount"
                hide-default-footer
                :footer-props="{'items-per-page-options': rowsPerPageItems}">
                <template slot="body.prepend">
                    <slot name="body.prepend"></slot>
                </template>
                <template slot="item" slot-scope="props">
                    <slot name="item" v-bind="props"></slot>
                </template>
                <template slot="no-results">
                    {{ $t(`messages['no data']`) }}
                </template>
                <template slot="no-data">
                    {{ $t(`messages['no data']`) }}
                </template>
            </v-data-table>
        </div>
    </table-sticky-wrapper>
</template>
<script>
import TableStickyWrapper from "./TableStickyWrapper"

const ROW_PER_PAGE_ITEMS = [10, 20, 50]

const numberOrZero = (value) => isNaN(value) ? 0 : value

const getTableOptions = (data = {}) => {
    const page = numberOrZero(data.offset / data.limit) + 1
    const sorting = (data.orderBy || "").split(" ")

    return {
        page,
        itemsPerPage: data.limit || ROW_PER_PAGE_ITEMS[0],
        sortBy: [sorting[0]],
        sortDesc: [sorting[1] === "desc"],
    }
}

const getSortingFromOptionsRaw = (val) => {
    const sortBy = (val.sortBy || [])[0]
    const sortDesc = (val.sortDesc || [])[0]

    return {
        sortBy: sortBy || "PhotosStatistic.uploaded",
        sortDesc: sortDesc ? "desc" : "asc",
    }
}

const getSortingFromOptions = (val) => {
    const { sortBy, sortDesc } = getSortingFromOptionsRaw(val)
    return `${sortBy} ${sortDesc}`
}

const getRequestOptions = (opts) => {
    return {
        limit: opts.itemsPerPage,
        offset: opts.itemsPerPage * (opts.page - 1),
        orderBy: getSortingFromOptions(opts),
    }
}

export default {
    components: { TableStickyWrapper },
    props: {
        headers: {
            type: Array,
            default: () => ([]),
        },
        value: {
            type: Object,
            default: () => ({}),
        },
        loading: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            rowsPerPageItems: ROW_PER_PAGE_ITEMS,
            page: 1,
            opts: Object.assign({}, getTableOptions(this.options)),
        }
    },

    computed: {
        getHeaders() {
            return this.headers.map(h => {
                const className = " subtitle-2 font-weight-light text--darken-3"
                if (h.class) {
                    h.class += className
                } else {
                    h.class = className
                }
                return h
            })
        },

        getItems() {
            try {
                return this.value.collection
            } catch (e) {
                return []
            }
        },

        getItemsCount() {
            try {
                return this.value.count
            } catch (e) {
                return 0
            }
        },
    },

    mounted() {
        this.$watch("opts", this.onUpdateOptions, { deep: true })
        // this.initializeScroll()
    },

    beforeDestroy() {
        // this.unObserveTableWidth()
    },

    methods: {
        onUpdateOptions(val, oldVal) {
            const newSorting = getSortingFromOptionsRaw(val)
            const oldSorting = getSortingFromOptionsRaw(oldVal)
            if (newSorting.sortBy !== oldSorting.sortBy) {
                this.opts.sortDesc = [true]
            } else {
                this.$emit("update:options", getRequestOptions(this.opts))
            }
        },
    },
}
</script>

<style lang="stylus">
    .data-table {
        .v-data-table td,
        .v-data-table th {
            padding-left: 6px;
            padding-right: 6px;
        }

        .v-data-table th {
            background: #fff;
            position: relative;
            z-index: 2;
            border-left: 1px solid #dedede;

            &:first-child {
                border 0
            }
        }
    }


    .data-table {
        .data-table__sticky_x {
            position: sticky;
            background: #fff;
            top: 1px;
            left 0
            z-index 0
            border-right: 1px solid #dedede;
            box-shadow 4px 0 4px rgba(0, 0, 0, 0.2)
        }

        tbody tr:hover .data-table__sticky_x {
            background: #eeeeee;
        }
    }


    .data-table tr.data-table__total,
    .data-table tr.data-table__total .data-table__sticky_x,
    .data-table tr.data-table__total:hover {
        background: #000000 !important;
        color: #fff !important;
    }
</style>
