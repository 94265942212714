<template>
    <div class="stat-export">
        <v-container fluid style="background: #fff">
            <p class="headline mb-6"> {{ $t(`headers['ranged stats']`, [getRangeLabel]) }}</p>
            <BaseFilter v-model="dateRange">
                <template slot="autocomplete">
                    <div class="stat-export__range-text">{{ $t("messages.choose range") }}</div>
                </template>
                <a
                    slot="submit"
                    :href="href"
                    target="_blank"
                    class="stat-export__link"
                >
                    <v-btn type="button" outlined ripple color="blue-grey darken-1">
                        {{ $t("actions.download") }}
                    </v-btn>
                </a>
            </BaseFilter>
        </v-container>
    </div>
</template>

<script>
import dayjs from "dayjs"
import * as Format from "../../util/format"

import Filter from "../Filter.vue"

export default {
    components: { BaseFilter: Filter },

    data() {
        const now = dayjs()
        return {
            dateRange: {
                begin: now.startOf("month").toDate(),
                end: now.endOf("day").toDate()
            },
        }
    },

    computed: {
        getRangeLabel() {
            const { begin, end } = this.dateRange
            return Format.dateRange(begin, end)
        },

        href() {
            const offset = this.dateRange.begin.getTimezoneOffset() * 60
            const dateStart = dayjs(this.dateRange.begin).unix() - offset
            const dateEnd = dayjs(this.dateRange.end).unix() - offset
            return `https://storemood.com/api/statistics/export?dateStart=${dateStart}&dateEnd=${dateEnd}`
        }
    }
}
</script>

<style lang="stylus" scoped>
.stat-export {
  .stat-export__range-text {
    line-height 62px
    font-size 20px
  }

  .stat-export__link {
    text-decoration none
  }
}
</style>
