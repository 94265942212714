<template>
    <v-form class="login-form" ref="form" @submit.prevent="onSubmit()">
        <v-text-field
            v-model="model.identity"
            :label="$t(`labels['identity']`)"
            name="identity"
            ref="identity"
            prepend-icon="mdi-account"
            :rules="[rules.required, rules.identity]"
            :disabled="loading"
            type="text"/>

        <v-text-field
            v-model="model.password"
            :rules="[rules.required, rules.password]"
            :disabled="loading"
            prepend-icon="mdi-lock"
            name="password"
            ref="password"
            :label="$t(`labels['password']`)"
            type="password"/>
        <v-spacer/>

        <div class="text-right">
            <v-btn outlined ripple color="blue-grey darken-1" :disabled="loading" type="submit">
                {{$t(`actions.login`)}}
            </v-btn>
        </div>
    </v-form>
</template>
<script>
const DEFAULT_VALUE = { identity: "", password: "" }

export default {
    name: "LoginForm",
    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            localErrors: this.errors,
            model: Object.assign({}, DEFAULT_VALUE, this.value),
            rules: {
                required: (value) => !!value || this.$t(`errors['field required']`),
                identity: () => "identity" in this.localErrors ? this.localErrors.identity : false,
                password: () => "password" in this.localErrors ? this.localErrors.password : false,
            },
        }
    },
    watch: {
        model: {
            deep: true,
            handler(val) {
                this.localErrors = {}
                this.$emit("input", val)
            },
        },

        errors: {
            deep: true,
            handler(val) {
                this.localErrors = val
                this.validate()
            },
        },
    },
    methods: {
        onSubmit() {
            if (!this.validate()) {
                this.$emit("submit")
            }
        },
        validate() {
            this.$refs.form.validate()
        }
    },
}
</script>

