<template>
    <v-container fluid>
        <div class="d-flex service-reports__heading align-center" :style="{'top': `${$vuetify.application.top}px`}">
            <h3 class="headline"> {{ $t(`headers['reports for']`, [getRangeLabel]) }}</h3>
            <select-range disabled-days @submit="onSelectRange"/>
        </div>
        <div class="mt-8">
            <income-report-controller :date-range="dateRange"/>
            <worksheets-report :date-range="dateRange"/>
            <visits-report :date-range="dateRange"/>
            <view-photos-report :date-range="dateRange"/>
        </div>
    </v-container>
</template>

<script>
import dayjs from "dayjs"
import IncomeReportController from "./IncomeReportController"
import VisitsReport from "../statistics/VisitsReport"
import ViewPhotosReport from "../statistics/ViewPhotosReport"
import WorksheetsReport from "../statistics/WorksheetsReport"
import SelectRange from "../SelectRange"
import Format from "../../util/format"

export default {
    components: { ViewPhotosReport, VisitsReport, WorksheetsReport, SelectRange, IncomeReportController },
    data() {
        return {
            dateRange: { begin: dayjs().subtract(1, "month").toDate(), end: dayjs().toDate() },
        }
    },
    computed: {
        getRangeLabel() {
            const { dateRange } = this
            return Format.dateRange(dateRange.begin, dateRange.end)
        },
    },
    methods: {
        onSelectRange(dateRange) {
            this.dateRange = dateRange
            this.dateRange.now = Date.now()
        },
    },
}
</script>
<style>
    .service-reports__heading {
        height: 56px;
        background: #ffffff;
        position: sticky;
        z-index: 2;
    }
</style>
