<template>
    <v-autocomplete
        :label="label"
        :hint="hint"
        autocomplete="off"
        clearable
        :search-input.sync="query"
        :items="getOptions"
        :loading="loading"
        :multiple="multiple"
        :cache-items="false"
        :rules="rules"
        :hide-no-data="isHiddenNodata"
        :no-data-text="noDataText"
        :item-text="computedItemText"
        :item-value="computedItemValue"
        :no-filter="noFilter"
        v-model="model"
        persistent-hint>
        <template v-slot:selection="data" v-if="multiple">
            <v-chip
                :close="true"
                :input-value="data.selected"
                @click="data.select"
                @click:close="removeItem(data.item)">
                <v-avatar left v-if="data.item.image">
                    <v-img :src="data.item.image"></v-img>
                </v-avatar>
                {{ data.item.name }}
            </v-chip>
        </template>
    </v-autocomplete>
</template>

<script>
import { i18n } from "../plugins/i18n"

const valueToArray = (item) => {
    if (!item) {
        return []
    }

    return Array.isArray(item) ? [...item] : [item]
}

const isEqualArray = (value, compare) => {
    return value.length === compare.length && value.every((item, i) => item === compare[i])
}

export default {
    props: {
        label: String,
        hint: String,
        noDataText: {
            type: String,
            default: i18n.t(`labels['nothing found']`),
        },
        loading: Boolean,
        multiple: {
            type: Boolean,
            default: true,
        },
        itemValue: {
            type: String,
            default: "id",
        },
        itemText: String,
        noFilter: Boolean,
        itemOriginal: String,
        items: Array,
        rules: Array,
        value: [Array, Object],
        toOption: Function,
        default: () => (val) => val,
    },
    data() {
        return {
            query: "",
            model: this.getModel(this.value),
            list: this.unique([...valueToArray(this.value), ...valueToArray(this.items)]),
            cache: new Map(),
        }
    },
    computed: {
        isHiddenNodata() {
            return this.loading || this.isEmptyQuery
        },

        isEmptyQuery() {
            return !this.query
        },

        getOptions() {
        // const cache = this.cache
        // const cached = Array.from(cache.values())
            return ([].concat(this.list) |> this.unique).map(item => this.getOption(item))
        },

        computedItemText() {
            return this.itemText || "name"
        },

        computedItemValue() {
            return this.itemValue || "id"
        },
    },
    watch: {
        query: {
            handler() {
                this.onChangeQuery()
            },
            immediate: true,
        },
        items: {
            handler() {
                // eslint-disable-next-line no-console
                this.list = this.unique([...valueToArray(this.value), ...valueToArray(this.items)])
                this.cacheItems()
            },
            immediate: true,
        },
        model: {
            handler() {
                if (this.multiple) {
                    this.onChangeMultipleModel()
                } else {
                    this.onChangeSingleModel()
                }
            },
            immediate: true,
        },
        value: {
            handler() {
                if (this.multiple) {
                    this.onChangeMultipleValue()
                } else {
                    this.onChangeSingleValue()
                }
                this.cacheItems()
            },
        },
    },
    created() {
        this.cacheItems()
    },
    methods: {
        getItemText() {
            return this.itemText || "name"
        },

        getItemValue() {
            return this.itemValue || "id"
        },

        onChangeMultipleModel() {
            this.$emit("input", this.getItemsByKeys(this.model))
        },

        onChangeSingleModel() {
            const item = this.cache.get(this.model)
            this.$emit("input", item || null)
        },

        onChangeMultipleValue() {
            const model = this.getModel(this.value)
            if (!isEqualArray(model, this.model)) {
                this.model = this.getModel(this.value)
                this.cacheItems()
            }
        },

        onChangeSingleValue() {
            this.model = this.value ? this.itemEntry(this.value)[0] : null
        },

        getOption(item) {
            return this.toOption(item)
        },

        cacheItems() {
            const list = this.unique([...valueToArray(this.value), ...valueToArray(this.items)])
            list.map(item => this.cache.set(...this.itemEntry(item)))
            this.list = list
        },

        itemEntry(item) {
            const option = this.toOption(item)
            return [option[this.getItemValue()], item]
        },

        unique(array) {
            const keys = array.map(item => this.itemEntry(item)[0])
            return array.filter((item, i) => keys.indexOf(keys[i]) === i)
        },

        onChangeQuery() {
            this.$emit("change", this.query || "")
        },

        getItemsByKeys(keys) {
            const cached = Array.from(this.cache.values())
            return cached.filter(item => keys.includes(this.itemEntry(item)[0]))
        },

        getKeysFromItems(items) {
            return items.map(item => this.itemEntry(item)[0])
        },

        getModel(value) {
            if (this.multiple) {
                return this.getKeysFromItems(value)
            }

            if (!value) {
                return null
            }

            return this.itemEntry(value)[0]
        },

        removeItem(item) {
            const key = item[this.getItemValue()]
            this.model = this.model.filter(k => k !== key)
        },
    },
}
</script>
