import EventEmitter from "events"
import URL from "url"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import deepAssign from "./deep-assign"

dayjs.extend(utc)

const timeZoneOffset = Math.abs(new Date().getTimezoneOffset() * 60)

const isDate = (value) => value instanceof Date || dayjs(value).isValid() && typeof value !== "number"

const formatQueryParameter = (value) => {
    if (Array.isArray(value)) {
        return value.join(",")
    } else if (isDate(value) || dayjs.isDayjs(value)) {
        return dayjs(value).unix() + timeZoneOffset
    }
    return value
}

const isEmptyParameter = (value) => typeof value === "undefined" || value === "" || value === null

const formatQuery = (value = {}) => {
    const result = {}
    Object.keys(value).forEach(key => {
        const param = formatQueryParameter(value[key])
        if (!isEmptyParameter(param)) {
            result[key] = param
        }
    })
    return result
}

const resource = new class Resource extends EventEmitter {
    constructor() {
        super()
        this.defaults = {}
    }

    setDefaults(defaults = {}) {
        this.defaults = defaults
    }

    setAuthorization(authorization) {
        this.setDefaults(
            deepAssign(this.defaults, {
                headers: {
                    "Authorization": authorization,
                },
            }),
        )
    }

    mergeOptions(options) {
        return deepAssign(options, this.defaults)
    }

    getDefaultOptions(method) {
        return {
            method: method,
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: { "Content-Type": "application/json", "X-CLIENT-ID": "manage.storemood.com" },
            redirect: "follow",
            referrer: "no-referrer",
        }
    }

    getOptions(method, headers = {}) {
        const defaults = this.getDefaultOptions(method)
        Object.assign(defaults, { headers: Object.assign(defaults.headers || {}, headers) })
        return this.mergeOptions(defaults)
    }

    buildUrl(path, query = {}) {
        const url = URL.parse(path, true)
        const defaultUrl = URL.parse(this.defaults.base)

        if (!url.host) {
            url.host = defaultUrl.host
            url.protocol = defaultUrl.protocol
            url.port = defaultUrl.port
            url.path = `${defaultUrl.path}${url.path}`
            url.pathname = url.path
        }

        url.query = Object.assign(url.query || {}, formatQuery(query))

        return url.format()
    }

    fetch(...args) {
        return fetch(...args).then((response) => {
            const success = (response.status >= 200 && response.status < 300) || response.status === 304
            if (!success) {
                throw response
            }
            return response
        })
    }

    GET(path, query = {}) {
        const url = this.buildUrl(path, query)
        const options = this.getOptions("GET")
        return this.fetch(url, options)
    }

    GET_JSON(...args) {
        return this.GET(...args).then(r => r.json())
    }

    POST(url, body, headers) {
        const options = this.getOptions("POST", headers)
        delete options.headers['Content-Type'];
        return this.fetch(url, { ...options, body: body })
    }

    POST_FORM(url, body) {
        return this.POST(url, body)
    }

    POST_JSON(...args) {
        return this.POST(...args).then(r => r.json())
    }
}

export default resource
export const GET = resource.GET.bind(resource)
export const GET_JSON = resource.GET_JSON.bind(resource)
export const POST = resource.POST.bind(resource)
export const POST_JSON = resource.POST_JSON.bind(resource)
export const POST_FORM = resource.POST_FORM.bind(resource)
